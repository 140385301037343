import React, { useEffect, useState, useRef } from "react";
import { withRouter } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {
  MDBContainer,
  MDBTypography,
  MDBCol,
  MDBCardBody,
  MDBCardText,
  MDBView,
  MDBListGroup,
  MDBListGroupItem,
  MDBAnimation,
} from "mdbreact";
import FittedImage from "react-fitted-image";

import useContainerDimensions from "./UseResizeHook";

import firebaseSvc from "../../firebaseSvc";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    //partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
    //partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1, // optional, default to 1.
  },
};

function ArticlesCarousel(props) {
  const [articles, setArticles] = useState([]);

  // setting up ref to caclulate width and max chars
  // const listItemRef = useRef();
  // const { width, height } = useContainerDimensions(listItemRef);
  // const [fetched, setFetched] = useState(false);

  useEffect(() => {
    async function fetchData() {
      // You can await here
      setArticles(await firebaseSvc.getLatestArticles(10));
      // setFetched(true);
    }
    fetchData();
  }, []); // Or [] if effect doesn't need props or state

  // useEffect(() => {
  //   console.log(width);
  // }, [width, fetched]);

  return (
    <MDBContainer style={{ marginBottom: "4rem" }}>
      <Carousel
        swipeable={false}
        draggable={true}
        showDots={false}
        responsive={responsive}
        ssr={false} // means to render carousel on server-side.
        infinite={true}
        autoPlay={props.deviceType !== "mobile" ? true : false}
        autoPlaySpeed={5000}
        keyBoardControl={false}
        arrows={true}
        focusOnSelect={true}
        partialVisible={false}
        transitionDuration={500}
        deviceType={props.deviceType}
        renderDotsOutside={false}
      >
        {/* Cards */}
        {articles.map((article, i) => (
          <MDBAnimation
            type="fadeIn"
            delay={i * 100 + "ms"}
            duration="700ms"
            key={i}
          >
            <div
              style={{
                height: "400px",
              }}
            >
              <MDBTypography
                style={{ display: "flex", justifyContent: "center" }}
                tag="h4"
              >
                <strong>{article.title.substring(0, 30) + "..."}</strong>
              </MDBTypography>

              <MDBView
                hover
                zoom
                onClick={() => props.history.push("/article/" + article.id)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <FittedImage
                  style={{
                    maxHeight: "250px",
                    minHeight: "250px",
                  }}
                  src={article.picture}
                  fit="cover"
                />
              </MDBView>
              <MDBCardBody>
                <a href={"/article/" + article.id} style={{ color: "#000" }}>
                  <MDBCardText>
                    {firebaseSvc
                      .removeHTMLTags(article.content)
                      .substring(0, 180) + "..."}
                  </MDBCardText>
                </a>
              </MDBCardBody>
            </div>
          </MDBAnimation>
        ))}
      </Carousel>
      <br />
      {/* List of latest articles */}
      <MDBAnimation type="fadeInUp" delay={"1550ms"} duration="700ms">
        <MDBCol>
          <MDBListGroup className="shadow">
            {articles.map((article, i) => (
              <MDBAnimation
                type="fadeIn"
                delay={i * 100 + "ms"}
                duration="700ms"
                key={i}
              >
                {/* <div ref={listItemRef}> */}
                <MDBListGroupItem
                  hover
                  onClick={() => props.history.push("/article/" + article.id)}
                  style={{
                    height: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <p
                    style={{
                      maxWidth: `calc(100% - 70px)`,
                      alignSelf: "center",
                    }}
                  >
                    {article.title}
                  </p>
                  <p style={{ float: "right" }}>
                    {firebaseSvc.getArticleDateShort(article.lastUpdate)}
                  </p>
                </MDBListGroupItem>
                {/* </div> */}
              </MDBAnimation>
            ))}
          </MDBListGroup>
        </MDBCol>
      </MDBAnimation>
    </MDBContainer>
  );
}

export default withRouter(ArticlesCarousel);
