import app from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/storage";
//import FileUploader from "react-firebase-file-uploader";
import cookie from "react-cookies";

const firebaseConfig = {
  apiKey: "AIzaSyDiJKel-WOY09pr7fw2oVszZ588uf2vIu4",
  authDomain: "sito-papi.firebaseapp.com",
  databaseURL: "https://sito-papi.firebaseio.com",
  projectId: "sito-papi",
  storageBucket: "sito-papi.appspot.com",
  messagingSenderId: "1004948415467",
  appId: "1:1004948415467:web:31742b715156c2e24aafae",
  measurementId: "G-6EKWNC0TWF",
};

class FirebaseSvc {
  constructor() {
    app.initializeApp(firebaseConfig);

    this.language = "ita"; //or eng

    //refresh page
    this.activePage = null;
    this.lastPage = null;

    this.language = cookie.load("language") || "ita";
    this.isAdmin = false;
    this.auth = app.auth();
    this.googleProvider = new app.auth.GoogleAuthProvider();
    this.facebookProvider = new app.auth.FacebookAuthProvider();

    // Get a database reference to our posts
    this.database = app.database();
    this.storage = app.storage();

    //get user data
    this.globalUser = null;

    //image uploading handling
    this.isUploading = false;
    this.progress = 0;

    //languages
    this.namesMap = {
      eng: {
        rooms: "Rooms",
      },
      ita: {
        rooms: "Stanze",
      },
    };
  }

  //   //LOGIN --------------------------------------------------------------------------------------------------------------------
  doSignInWithGoogle = async (callback) => {
    let user = await this.auth.signInWithPopup(this.googleProvider);
    user = user.user;

    if (user.uid != "OPaplKPeWuOp7Xei3JOukByM9Bb2") {
      //alert("Solo l'amministratore puo' accedere all' area admin del sito");
      cookie.remove("userID");
      return;
    }

    //cache user details
    this.globalUser = {
      name: user.displayName,
      email: user.email,
      photo: user.photoURL,
      uid: user.uid,
    };

    await cookie.save("userID", user.uid);
    callback();
  };

  doSignOut = async () => {
    //console.log("Signing out");
    cookie.remove("userID");
    await this.auth.signOut();
    //console.log(cookie.load("userID"));
    //window.location.reload(false);
    //console.log(cookie.loadAll());
  };

  doPasswordReset = (email) => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = (password) =>
    this.auth.currentUser.updatePassword(password);

  checkAuth = async () => {
    this.auth.onAuthStateChanged((user) => {
      if (user) {
        cookie.save("userID", user.uid);
        return user;
      } else return null;
    });
  };

  checkLogin = () => {
    console.log(cookie.load("userID"));
    return cookie.load("userID") != null;
  };

  //   //USER MANAGEMENT
  //   confirmUserDetailsOnDB = async () => {
  //     let user = null;
  //     let UID = cookie.load("userID");

  //     if (UID != null) console.log("Confirming user details for " + UID);
  //     else if (this.globalUser != null)
  //       console.log("Confirming user details for " + this.globalUser.uid);
  //     else {
  //       console.log("UID not found locally");
  //       return null;
  //     }
  //     if (UID != null) {
  //       user = await this.database
  //         .ref("/USERS")
  //         .child(UID)
  //         .once("value", function(snap) {
  //           user = snap.val();
  //         });

  //       user = user.val();
  //     }
  //     //we don't have them on DB, let's change that
  //     if (user === null && this.globalUser != null) {
  //       UID = this.globalUser.uid;
  //       //create a DB user
  //       user = this.globalUser;

  //       let dbUser = {
  //         name: user.name,
  //         email: user.email,
  //         photo: user.photo,
  //         lastPayment: "none"
  //       };

  //       await this.database.ref("/USERS").update({ [UID]: dbUser });

  //       user = dbUser;
  //       user.uid = UID;

  //       console.log("User created on DB");
  //     } else if (user === null && this.globalUser == null) {
  //       console.log("Could not confirm user");
  //       return null;
  //     }
  //     //update user details
  //     this.globalUser = {
  //       name: user.name,
  //       email: user.email,
  //       photo: user.photo,
  //       lastPayment: "none",
  //       uid: UID
  //     };
  //     //we found our guy, let's update stuff
  //   };

  fetchMainBackground = async () => {
    let result = null;
    await this.database
      .ref("settings")
      .child("homeImg")
      .once("value", async (snapshot) => {
        result = snapshot.val();
      });
    return result;
  };

  setMainBackground = async (url) => {
    await this.database
      .ref("settings")
      .child("homeImg")
      .set(url, function (error) {
        if (error) {
          // The write failed...
          console.log(error);
        } else {
          // Data saved successfully!
          console.log("main image updated");
          return url;
        }
      });
  };

  fetchSiteDesc = async () => {
    console.log(this.language);
    let result = null;
    await this.database
      .ref("settings")
      .child(this.language)
      .child("siteDesc")
      .once("value", async (snapshot) => {
        result = snapshot.val();
      });
    return result;
  };

  setSiteDesc = async (txt) => {
    await this.database
      .ref("settings")
      .child(this.language)
      .child("siteDesc")
      .set(txt, function (error) {
        if (error) {
          // The write failed...
          console.log(error);
        } else {
          // Data saved successfully!
          console.log("main description updated");
        }
      });
  };

  //Handle image upload

  handleMainBkgUpload = async (imageData, imageName, callback) => {
    const uploadTask = this.storage.ref(`images/${imageName}`).put(imageData);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      (error) => {
        // Error function ...
        console.log(error);
      },
      () => {
        // complete function ...
        this.storage
          .ref("images")
          .child(imageName)
          .getDownloadURL()
          .then(async (url) => {
            await this.setMainBackground(url);
            callback(url);
            return url;
          });
      }
    );
  };

  //ROOMS ----------------------------------------------------------------------------------------------------------------------------------

  createRoom = async (roomDetails) => {
    let neededDeets = {
      name: roomDetails.name,
      description: roomDetails.description,
      picture: roomDetails.picture,
    };

    let referenceID = await this.database
      .ref("Rooms")
      .child(this.language)
      .push(neededDeets);
    return referenceID.key;
  };

  getRooms = async () => {
    let result = [];
    await this.database
      .ref("Rooms")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          result.push({
            id: element.key,
            name: element.val().name,
            picture: element.val().picture,
          });
        });
      });
    return result;
  };

  deleteRoomWithID = async (ID) => {
    let result = null;
    await this.database.ref("Rooms").child(this.language).child(ID).remove();
    return result;
  };

  getRoomFromID = async (ID) => {
    let result = null;
    await this.database
      .ref("Rooms")
      .child(this.language)
      .child(ID)
      .once("value", (snapshot) => {
        result = snapshot.val();
      });
    return result;
  };

  //ARTICLES ----------------------------------------------------------------------------------------------------------------------------------

  createArticle = async (articleDetails) => {
    let neededDeets = {
      lastUpdate: Date.now(),
      title: articleDetails.title,
      picture: articleDetails.picture,
      content: articleDetails.content,
      associatedRooms: articleDetails.articleRoomIDs,
    };

    let referenceID = await this.database
      .ref("Articles")
      .child(this.language)
      .push(neededDeets);
    return referenceID.key;
  };

  getArticles = async () => {
    let result = [];
    await this.database
      .ref("Articles")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          result.push({
            id: element.key,
            lastUpdate: element.val().lastUpdate,
            title: element.val().title,
            content: element.val().content,
            picture: element.val().picture,
          });
        });
      });
    return result;
  };

  getLatestArticles = async (limit) => {
    let result = [];
    await this.database
      .ref("Articles")
      .child(this.language)
      .orderByChild("lastUpdate")
      .limitToLast(limit)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          result.push({
            id: element.key,
            lastUpdate: element.val().lastUpdate,
            title: element.val().title,
            content: element.val().content,
            picture: element.val().picture,
          });
        });
      });

    let orderedResults = result.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.lastUpdate) - new Date(a.lastUpdate);
    });

    //console.log(orderedResults);
    return orderedResults;
  };

  getArticlesInRoom = async (roomID) => {
    let result = [];
    await this.database
      .ref("Articles")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          if (
            element.val().rooms != null &&
            element.val().rooms.includes(roomID)
          )
            result.push({
              id: element.key,
              lastUpdate: element.val().lastUpdate,
              title: element.val().title,
              content: element.val().content,
              picture: element.val().picture,
            });
        });
      });

    let orderedResults = result.sort(function (a, b) {
      // Turn your strings into dates, and then subtract them
      // to get a value that is either negative, positive, or zero.
      return new Date(b.lastUpdate) - new Date(a.lastUpdate);
    });
    return orderedResults;
  };

  getArticleFromID = async (ID) => {
    let result = null;
    await this.database
      .ref("Articles")
      .child(this.language)
      .child(ID)
      .once("value", (snapshot) => {
        result = snapshot.val();
      });
    return result;
  };

  removeArticle = async (ID) => {
    await this.database.ref("Articles").child(this.language).child(ID).remove();
  };

  //LINKS ----------------------------------------------------------------------------------------------------------------------------------

  createCategory = async (categoryDeets) => {
    let neededDeets = {
      lastUpdate: Date.now(),
      text: categoryDeets.title,
    };

    let referenceID = await this.database
      .ref("/Category")
      .child(this.language)
      .push(neededDeets);
    return referenceID.key;
  };

  getCategories = async () => {
    let result = [];
    await this.database
      .ref("Category")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          result.push({
            id: element.key,
            name: element.val().text,
          });
        });
      });
    return result;
  };

  createLink = async (linkDeets) => {
    let neededDeets = {
      lastUpdate: Date.now(),
      title: linkDeets.title,
      description: linkDeets.description,
      url: linkDeets.url,
      category: linkDeets.category,
      room: linkDeets.room,
    };

    let referenceID = await this.database
      .ref("/Links")
      .child(this.language)
      .push(neededDeets);
    return referenceID.key;
  };

  getLinks = async () => {
    let result = [];
    await this.database
      .ref("Links")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          result.push({
            id: element.key,
            title: element.val().title,
            description: element.val().description,
            url: element.val().url,
            category: element.val().category,
            room: element.val().room,
          });
        });
      });
    return result;
  };

  getLinksInCategory = async (catID) => {
    let result = [];
    await this.database
      .ref("Links")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          console.log(element.val().category.find((c) => c == catID));
          if (element.val().category.find((c) => c == catID).length > 0)
            result.push({
              id: element.key,
              title: element.val().title,
              description: element.val().description,
              url: element.val().url,
              category: element.val().category,
              room: element.val().room,
            });
        });
      });
    return result;
  };

  getLinksInRoom = async (roomID) => {
    let result = [];
    await this.database
      .ref("Links")
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((element) => {
          if (element.val().room != null && element.val().room.includes(roomID))
            result.push({
              id: element.key,
              title: element.val().title,
              description: element.val().description,
              url: element.val().url,
              category: element.val().category,
              room: element.val().room,
            });
        });
      });
    return result;
  };

  getLinkFromID = async (ID) => {
    let result = null;
    await this.database
      .ref("Links")
      .child(this.language)
      .child(ID)
      .once("value", (snapshot) => {
        result = snapshot.val();
      });
    return result;
  };

  updateLinkWithID = async (ID, deets) => {
    await this.database
      .ref("Links")
      .child(this.language)
      .update({ [ID]: deets });
  };

  removeLink = async (ID) => {
    await this.database.ref("Links").child(this.language).child(ID).remove();
  };

  //GENERAL UTILITY ------------------------------------------------------------------------------------------------------------------------

  updateField = async (ref, ID, field, data) => {
    await this.database
      .ref(ref)
      .child(this.language)
      .child(ID)
      .update({ [field]: data });
  };

  getField = async (ref, ID, field) => {
    let res = null;
    await this.database
      .ref(ref)
      .child(this.language)
      .child(ID)
      .child(field)
      .once("value", (snapshot) => {
        res = snapshot.val();
      });
    return res;
  };

  getList = async (listName) => {
    let list = [];
    await this.database
      .ref(listName)
      .child(this.language)
      .once("value", (snapshot) => {
        snapshot.forEach((item) => {
          list.push(item.val());
          list[list.length - 1].id = item.key;
        });
      });
    console.log(list);
    return list;
  };

  handleImageUpload = async (imageData, imageName, ref, ID, callback) => {
    console.log("Uploading image");
    const uploadTask = this.storage.ref(`images/${imageName}`).put(imageData);
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // progress function ...
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
        console.log(progress);
      },
      (error) => {
        // Error function ...
        console.log(error);
      },
      async () => {
        console.log("Completed upload, setting reference");
        // complete function ...
        let url = await this.storage
          .ref("images")
          .child(imageName)
          .getDownloadURL();

        console.log("Image uploaded to storage : " + url);
        await this.setUpdatedImageInDB(url, ref, ID);
        callback(url);
        return url;
      }
    );
  };

  setUpdatedImageInDB = async (url, ref, ID) => {
    let img = await this.database
      .ref(ref)
      .child(this.language)
      .child(ID)
      .update({ picture: url }, function (error) {
        if (error) {
          // The write failed...
          console.log(error);
        } else {
          // Data saved successfully!
          console.log("image updated on the DB");
        }
      });
    return img;
  };

  getArticleDate = (date) => {
    let d = new Date(date);
    let stringDate =
      d.getDate() + " - " + d.getMonth() + " - " + d.getFullYear();
    //stringDate = JSON.stringify(d);
    return stringDate;
  };

  getArticleDateShort = (date) => {
    let d = new Date(date);
    let stringDate =
      d.getDate() +
      "/" +
      d.getMonth() +
      "/" +
      d.getFullYear().toString().slice(2, 4);
    //stringDate = JSON.stringify(d);
    return stringDate;
  };

  getArticleDateMY = (date) => {
    let d = new Date(date);
    let stringDate = d.getMonth() + " / " + d.getFullYear();
    //stringDate = JSON.stringify(d);
    return stringDate;
  };

  //NEWSLETTER -----------------------------------------------------------------------------------------
  addEmail = async (mail) => {
    var newMailKey = await this.database.ref().child("NewsLetter").push().key;
    await this.database
      .ref()
      .child("NewsLetter")
      .update({ [newMailKey]: mail });
    return true;
  };

  removeEmail = async (ID) => {
    await this.database.ref().child("NewsLetter").child(ID).remove();
    return true;
  };

  getEmails = async () => {
    let res = null;
    await this.database
      .ref()
      .child("NewsLetter")
      .once("value", (snapshot) => {
        res = snapshot.val();
      });
    return res;
  };

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
  };

  removeHTMLTags = (html) => {
    var regX = /(<([^>]+)>)/gi;
    let str = html.replace(regX, "");
    str = str.replace("&nbsp", "");
    return str + "...";
  };
}

const firebaseSvc = new FirebaseSvc();
export default firebaseSvc;
