import React from "react";
import "./App.css";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  withRouter,
} from "react-router-dom";

import cookie from "react-cookies";

//user
import Admin from "./components/AdminPanel";
import Home from "./components/HomeScreen";
import Room from "./components/RoomScreen";
import Article from "./components/ArticleScreen";
import ArticlesList from "./components/ArticlesList";
import RoomsList from "./components/RoomsList";
import AboutMe from "./components/AboutMe";
import Events from "./components/EventsScreen";
import Contacts from "./components/ContactsScreen";
//admin
import RoomEdit from "./editor/RoomScreenEditor";
import ArticleEdit from "./editor/ArticleEditor";
import LinkEdit from "./components/Links/LinkEditor";
import Policy from "./components/PrivacyPolicy";
//dynamic
import LinksScreen from "./components/Links/LinksScreen";
import LandingPage from "./components/LandingPage";

import firebaseSvc from "./firebaseSvc";

const PrivateRoute = ({ component: Component, redirect: Redir, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      cookie.load("userID") != null ? (
        <Component {...props} />
      ) : (
        <Redir {...props} />
      )
    }
  />
);

class App extends React.Component {
  state = {};

  componentDidMount = () => {
    //console.log(cookie.load("userID"));
  };

  render() {
    return (
      <Router>
        <Switch>
          <Route exact path="/admin" component={Admin} />
          <Route exact path="/landing" component={LandingPage} />

          <PrivateRoute
            path="/room/:roomID"
            component={RoomEdit}
            redirect={Room}
          />

          <PrivateRoute
            path="/article/:articleID"
            component={ArticleEdit}
            redirect={Article}
          />

          <PrivateRoute
            path="/links/editor"
            component={LinkEdit}
            redirect={Home}
          />

          <Route exact path="/articles" component={ArticlesList} />
          <Route exact path="/rooms" component={RoomsList} />
          <Route exact path="/links" component={LinksScreen} />
          <Route exact path="/about" component={AboutMe} />
          <Route exact path="/events" component={Events} />
          <Route exact path="/contacts" component={Contacts} />
          <Route exact path="/policy" component={Policy} />

          <PrivateRoute path="/" component={Home} redirect={Home} />

          {/* <Route path="/stanze">
            <About />
            </Route>
            <Route path="/blog">
            <About />
            </Route>
            <Route path="/link">
            <About />
            </Route>
            <Route path="/about">
            <About />
            </Route>
            <Route path="/contacts">
            <About />
          </Route> */}
        </Switch>
      </Router>
    );
  }
}

export default App;

/*

TODO: handle custom image sizes on editor
TODO: investigate image crop and resize on upload
TODO: implement sub-rooms
TODO: implement rooms list frontend
TODO: implement single room frontend

TODO: force refresh after changes / logout
TODO: implement articles (multi-tag)
TODO: implement links
TODO: implement books

*/
