import React, { Component } from "react";

import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

//sections
import BaseLayout from "./elements/BaseLayout";
import NavBar from "./elements/NavBar";
import Footer from "./elements/Footer";
import LoadingScreen from "./LoadingScreen";

import firebaseSvc from "../firebaseSvc";

import {
  MDBContainer,
  MDBBox,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBBtn,
  MDBAnimation,
} from "mdbreact";

class ContactsScreen extends Component {
  state = {
    isLoggedIn: false,
    email: "",
    emails: [],
    loading: false,
    subscribed: false,
    error: false,
    pageContents: null,
    editorState: EditorState.createEmpty(),
  };

  componentWillMount = async () => {
    this.setState({ loading: true });
    let data = await firebaseSvc.getField("StaticPages", "Contacts", "content");

    //Load content
    if (data == null) {
      const editorState = EditorState.createEmpty();
      this.setState({ editorState });
      this.setState({ pageContents: "<h1>Content not found</h1>" });
    } else {
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
        this.setState({ pageContents: data });
      }
    }
    this.setState({ loading: false });

    this.setState({ isLoggedIn: firebaseSvc.checkLogin() });
    if (firebaseSvc.checkLogin()) {
      await this.refreshEmails();
    }
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <NavBar />
        {this.state.isLoggedIn && <this.LoggedInView />}
        {!this.state.isLoggedIn && <this.UserView />}
        <Footer />
      </div>
    );
  }

  refreshEmails = async () => {
    this.setState({ loading: true });
    let entries = await firebaseSvc.getEmails();

    if (entries != null) {
      let keys = Object.keys(entries);
      let vals = Object.values(entries);
      let finalEmails = [];

      for (let i = 0; i < keys.length; i++) {
        finalEmails.push({ key: keys[i], val: vals[i] });
      }

      this.setState({ emails: finalEmails });
    } else this.setState({ emails: [] });

    this.setState({ loading: false });
  };

  addEmail = async () => {
    if (this.state.email.length == 0 || !this.validateEmail(this.state.email)) {
      this.setState({ email: "" });
      this.setState({ error: true });
      return;
    }
    let success = await firebaseSvc.addEmail(this.state.email);
    if (success) {
      this.setState({ subscribed: true });
      this.setState({ email: "" });
    }
  };

  removeEmail = async (ID) => {
    this.setState({ loading: true });
    await firebaseSvc.removeEmail(ID);
    await this.refreshEmails();
    this.setState({ loading: false });
  };

  validateEmail = (email) => {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  };

  savePage = async () => {
    this.setState({ loading: true });

    firebaseSvc.updateField(
      "StaticPages",
      "Contacts",
      "content",
      this.state.pageContents
    );

    this.setState({ loading: false });
  };

  copyToClipboard = (text) => {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
  };

  LoggedInView = () => (
    <React.Fragment>
      <MDBBtn
        className="mt-2 mb-2 pl-4 pr-4"
        variant="success"
        block
        onClick={this.savePage}
      >
        Salva
      </MDBBtn>
      <MDBRow style={{ maxWidth: "100%" }}>
        <MDBCol xs={9}>
          <BaseLayout
            disabled={!this.state.isLoggedIn}
            content={this.state.pageContents}
            onChange={(imageURL, htmlContent) => {
              this.setState({ pageContents: htmlContent });
            }}
          />
        </MDBCol>
        <MDBCol>
          <MDBCard className="mt-5">
            <form style={{ height: "800px", overflow: "auto" }}>
              <ul>
                {this.state.emails.map((email) => (
                  <MDBRow key={email.key} className="m-2">
                    <MDBBtn
                      className="badge-pill badge-danger mr-4 p-2"
                      onClick={() => this.removeEmail(email.key)}
                    >
                      X
                    </MDBBtn>
                    <p>{email.val}</p>{" "}
                  </MDBRow>
                ))}
              </ul>
            </form>
          </MDBCard>
          <MDBBtn
            onClick={() => {
              let mails = "";
              this.state.emails.forEach((mail, index) => {
                mails += mail.val;
                if (index < this.state.emails.length - 1) mails += ", ";
              });
              this.copyToClipboard(mails);
            }}
          >
            Copia Emails
          </MDBBtn>
        </MDBCol>
      </MDBRow>
    </React.Fragment>
  );

  UserView = () => (
    <MDBAnimation type="fadeIn" duration="700ms" delay={"450ms"}>
      <MDBContainer className="my-5">
        <MDBRow className="d-flex align-items-center ">
          <MDBCol lg="7">
            <BaseLayout
              disabled={!this.state.isLoggedIn}
              content={this.state.pageContents}
              onChange={(imageURL, htmlContent) => {
                this.setState({ pageContents: htmlContent });
              }}
            />
          </MDBCol>
          <MDBCol lg="5">
            <MDBCard className="p-5 shadow">
              <MDBRow className="d-flex justify-content-center">
                <h2>
                  {firebaseSvc.language == "ita" && "Iscriviti alla newsletter"}
                  {firebaseSvc.language != "ita" &&
                    "Subscribe to the newsletter to be always up to date."}
                </h2>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <input
                    type="text"
                    value={this.state.email}
                    onChange={(e) => this.setState({ email: e.target.value })}
                    className="w-100 mt-3 form-control-lg"
                  />
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <MDBBox display="flex" justifyContent="center">
                    <MDBBtn
                      className="btn-success mt-3 justify-center"
                      onClick={this.addEmail}
                    >
                      Subscribe
                    </MDBBtn>
                  </MDBBox>
                </MDBCol>
              </MDBRow>
              <MDBRow>
                <MDBCol>
                  <a className="float-right mt-2" href="/policy">
                    Privacy Policy
                  </a>
                </MDBCol>
              </MDBRow>
              <MDBRow className="d-flex justify-content-center">
                <a href="mailto:claudio.maddaloni@gmail.com" className="mt-4">
                  <h3>
                    {firebaseSvc.language != "ita" && "Or write me an e-mail"}{" "}
                    {firebaseSvc.language == "ita" &&
                      "Oppure scrivimi una mail"}
                  </h3>
                </a>
              </MDBRow>
            </MDBCard>
          </MDBCol>
        </MDBRow>
        {this.state.subscribed && (
          <div class="alert alert-success" role="alert">
            <strong>Thank you</strong> You now will receive important updates.
          </div>
        )}
        {this.state.error && (
          <div class="alert alert-danger" role="alert">
            <strong>Oh snap!</strong> Make sure to fill in your e-mail address.
          </div>
        )}
      </MDBContainer>
    </MDBAnimation>
  );
}

export default ContactsScreen;
