import React, { Component } from "react";

//sections
import NavBar from "./elements/NavBar";
import StaticPage from "./elements/StaticPageTemplate";

import firebaseSvc from "../firebaseSvc";

class AboutMeScreen extends Component {
  state = {
    isLoggedIn: false,
  };

  componentWillMount = async () => {
    this.setState({ isLoggedIn: firebaseSvc.checkLogin() });
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#f5f5f5",
        }}
      >
        <NavBar />
        <StaticPage
          title={firebaseSvc.language == "ita" ? "Su di me" : "About me"}
          dbURL="StaticPages/AboutMe"
          disabled={!this.state.isLoggedIn}
          round={true}
        />
      </div>
    );
  }
}

export default AboutMeScreen;
