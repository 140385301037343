import React, { Component } from "react";

class Footer extends Component {
  state = {};
  render() {
    return (
      <div
        className="bg-light fixed-bottom d-flex justify-content-center shadow"
        style={{ height: 25 }}
      >
        <p>Claudio Maddaloni</p>
      </div>
    );
  }
}

export default Footer;
