// stato con immagine, contenuto e flag per editare
import React, { Component, View, Text, Image } from "react";

//per l'immagine
import FittedImage from "react-fitted-image";

//per il contenuto
import { Modifier, EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

//per l'estetica
import "../../App.css";
import { MDBContainer, MDBRow, MDBCol, MDBAnimation } from "mdbreact";

import LoadingScreen from "../LoadingScreen";
import { withRouter } from "react-router-dom";

import Fullscreen from "react-full-screen";

class BaseLayout extends Component {
  state = {
    editable: false,
    imageURL: null,
    imageIsRound: false,
    editorState: EditorState.createEmpty(),
    htmlContent: null,
    callback: null,
    handleImageUpload: null,
    loading: false,
    isFull: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });

    //get image, editable and content
    const isEditable = !this.props.disabled;
    const propsImageURL = this.props.imageURL;
    const imageIsRound = this.props.round;
    const propsContents = this.props.content;

    //hook callback
    this.setState({ callback: this.props.onChange });
    this.setState({ handleImageUpload: this.props.handleImageUpload });

    //set state
    this.setState({ imageIsRound });
    this.setState({ editable: isEditable });
    this.setState({
      imageURL: propsImageURL,
    });
    const contentBlock = htmlToDraft(propsContents);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState });
    }
    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <MDBContainer className="mb-5">
        {this.state.imageURL && this.state.imageIsRound && (
          <MDBAnimation type="fadeIn" duration="700ms" delay={"150ms"}>
            <MDBRow className="d-flex d-block justify-content-center">
              <MDBRow
                className="mt-md-4"
                style={{
                  alignSelf: "center",
                  height: 300,
                  width: 300,
                  marginBottom: "80px",
                }}
              >
                <FittedImage
                  style={{ borderRadius: "50%" }}
                  src={this.state.imageURL}
                  fit="cover"
                  className="shadow"
                />
              </MDBRow>
            </MDBRow>
          </MDBAnimation>
        )}
        {this.state.imageURL && !this.state.imageIsRound && (
          <MDBAnimation type="fadeIn" duration="700ms">
            <MDBRow className="mt-md-4 mb-1" style={{ height: 400 }}>
              <MDBCol style={{ height: "100%" }}>
                <FittedImage src={this.state.imageURL} fit="cover" />
              </MDBCol>
            </MDBRow>
          </MDBAnimation>
        )}
        {this.state.editable && this.state.imageURL && (
          <MDBCol>
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={this.state.handleImageUpload}
            />
            <label className="btn btn-secondary" htmlFor="inputGroupFile01">
              Carica Immagine
            </label>
          </MDBCol>
        )}
        {this.state.editable && <this.Editable />}
        {!this.state.editable && <this.Viewer />}
      </MDBContainer>
    );
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
    this.setState({
      htmlContent: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    });
    this.state.callback &&
      this.state.callback(this.state.imageURL, this.state.htmlContent);
  };

  Viewer = () => (
    <MDBAnimation type="fadeIn" duration="700ms" delay={"350ms"}>
      <Editor
        handlePastedText={() => false}
        editorStyle={{
          border: "",
          backgroundColor: "#fff",
        }}
        toolbarHidden={true}
        ReadOnly={true}
        wrapperClassName="wrapperClassName"
        editorClassName="editorClassName shadow p-4"
        editorState={this.state.editorState}
      />
    </MDBAnimation>
  );

  Editable = () => (
    <MDBAnimation type="fadeIn" duration="700ms" delay={"350ms"}>
      <React.Fragment>
        <button onClick={() => this.setState({ isFull: true })}>
          Go Fullscreen
        </button>
        <Fullscreen
          enabled={this.state.isFull}
          onChange={(isFull) => this.setState({ isFull })}
        >
          <Editor
            editorStyle={{
              maxHeight: this.state.isFull ? "100vh" : "700px",
              minHeight: this.state.isFull ? "100vh" : "700px",
              scroll: "auto",
              overflow: "auto",
              border: "",
            }}
            toolbarHidden={false}
            ReadOnly={false}
            toolbar={{
              image: {
                uploadEnabled: true,
                previewImage: true,
              },
            }}
            wrapperClassName="wrapperClassName"
            editorClassName="editorClassName"
            handlePastedText={() => false}
            // handlePastedText={this.handlePastedText}
            editorState={this.state.editorState}
            onEditorStateChange={(editorState) => {
              //console.log(editorState.getCurrentContent().getBlockMap());
              this.handleEditorChange(editorState);
            }}
          />
        </Fullscreen>
      </React.Fragment>
    </MDBAnimation>
  );

  handlePastedText = (text, html, editorState) => {
    var content = editorState.getCurrentContent();
    var selection = editorState.getSelection();

    var newContent = Modifier.insertText(content, selection, text);

    var newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    //Then send this to onChange()
    return "handled";
  };
}

export default withRouter(BaseLayout);
