import React, { Component, View, Text, Image } from "react";
import { Link } from "react-router-dom";
import FittedImage from "react-fitted-image";
import convert from "htmr";

import stylingConstants from "../stylingConstants";
import GridGenerator from "./GridGenerator";

import NavBar from "./elements/NavBar";
import Footer from "./elements/Footer";
import LoadingScreen from "./LoadingScreen";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBView,
  MDBMask,
  MDBPagination,
  MDBPageItem,
  MDBPageNav,
  MDBAnimation,
  MDBCard,
  MDBCardBody,
  MDBCardImage,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
} from "mdbreact";
import { Grid, Button, TextField, IconButton } from "@material-ui/core";

import cookie from "react-cookies";
import firebaseSvc from "../firebaseSvc";

class ArticlesList extends Component {
  state = {
    articles: [],
    toVisualize: [],
    page: 0,
    pageSize: 5,
    loading: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });

    this.setState({ isAdmin: cookie.load("userID") != null });
    let roomID = null;
    if (this.props.location.state != null)
      roomID = this.props.location.state.roomID;

    let articles = [];

    if (roomID == null) {
      //list all articles
      articles = await firebaseSvc.getLatestArticles(9999);
    } else {
      //list articles within room
      articles = await firebaseSvc.getArticlesInRoom(roomID);
    }

    this.setState({ articles });

    this.setupPagination(articles);
    //this.gatherPagination();

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <NavBar />
        <MDBContainer
          style={{ backgroundColor: "#fff" }}
          className="z-depth-2 p-5"
        >
          <Grid
            container
            justify="flex-end"
            style={{
              height: "3rem",
            }}
          >
            <Grid
              item
              xs={12}
              sm={7}
              md={5}
              lg={5}
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth="0"
                  viewBox="0 0 16 16"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fill-rule="evenodd"
                    d="M10.442 10.442a1 1 0 011.415 0l3.85 3.85a1 1 0 01-1.414 1.415l-3.85-3.85a1 1 0 010-1.415z"
                    clip-rule="evenodd"
                  ></path>
                  <path
                    fill-rule="evenodd"
                    d="M6.5 12a5.5 5.5 0 100-11 5.5 5.5 0 000 11zM13 6.5a6.5 6.5 0 11-13 0 6.5 6.5 0 0113 0z"
                    clip-rule="evenodd"
                  ></path>
                </svg>
              </Grid>
              <Grid item xs={10} style={{ width: "100%" }}>
                <TextField
                  style={{ width: "100%", marginRight: "3rem" }}
                  label={firebaseSvc.language == "ita" ? "Cerca" : "Search"}
                  onChange={(e) => {
                    this.searchByName(e.target.value);
                  }}
                ></TextField>
              </Grid>
            </Grid>
          </Grid>

          <br></br>
          {this.state.isAdmin && (
            <MDBBtn
              onClick={() => this.props.history.push("/article/new")}
              className="list-group-item list-group-item-action list-group-item-success"
              style={{ margin: "20px" }}
            >
              Crea un nuovo articolo
            </MDBBtn>
          )}
          {this.state.toVisualize[this.state.page].map((article, index) => (
            <React.Fragment key={index}>
              <MDBAnimation
                type="fadeInUp"
                delay={index * 100 + "ms"}
                duration="700ms"
              >
                <MDBRow
                  className="my-1"
                  onClick={() =>
                    this.props.history.push("article/" + article.id)
                  }
                >
                  <MDBCol lg="5" xl="4" className="align-self-center">
                    <FittedImage
                      src={article.picture}
                      fit="cover"
                      className="shadow"
                      style={{
                        borderRadius: "50%",
                        height: "200px",
                        width: "200px",
                      }}
                    />
                    <a href="#!">
                      <MDBMask overlay="white-slight" />
                    </a>
                  </MDBCol>
                  <MDBCol lg="7" xl="8">
                    <h3 className="font-weight-bold mb-3 p-0">
                      <strong>{article.title}</strong>
                    </h3>
                    <p className="dark-grey-text">
                      {firebaseSvc
                        .removeHTMLTags(article.content)
                        .substring(0, 300) + "..."}
                    </p>
                    <div>
                      <div style={{ float: "left" }}>
                        <p>
                          by{" "}
                          <a href="/about" className="font-weight-bold">
                            Claudio Maddaloni
                          </a>
                          , {firebaseSvc.formatDate(article.lastUpdate)}
                        </p>
                      </div>
                      <div style={{ float: "right" }}>
                        <Button color="primary" size="medium">
                          {firebaseSvc.language == "ita" ? "Leggi" : "Read"}
                        </Button>
                        {this.state.isAdmin && (
                          <IconButton
                            variant="outlined"
                            style={{}}
                            onClick={async () => {
                              this.setState({ loading: true });
                              firebaseSvc.removeArticle(article.id);
                              window.location.reload(false);
                            }}
                          >
                            <svg
                              stroke="#ed2f2f"
                              fill="#ed2f2f"
                              strokeWidth="0"
                              viewBox="0 0 24 24"
                              height="1rem"
                              width="1rem"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g>
                                <path fill="none" d="M0 0h24v24H0z"></path>
                                <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"></path>
                              </g>
                            </svg>
                          </IconButton>
                        )}
                      </div>
                    </div>
                  </MDBCol>
                </MDBRow>
                <hr className="my-4" style={{ width: "10%" }} />
              </MDBAnimation>
            </React.Fragment>
          ))}

          <Grid
            container
            className="scrollbarHidden"
            style={{ overflow: "scroll", overflowY: "hidden" }}
          >
            <MDBPagination circle>
              <MDBPageItem disabled={this.state.page != 1}>
                <MDBPageNav
                  className="page-link"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    this.setState({ page: 0 });
                  }}
                >
                  <span>First</span>
                </MDBPageNav>
              </MDBPageItem>
              {this.state.toVisualize.map((page, index) => (
                <MDBPageItem
                  active={this.state.page == index}
                  key={index}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    this.setState({ page: index });
                  }}
                >
                  <MDBPageNav className="page-link">{index}</MDBPageNav>
                </MDBPageItem>
              ))}
              <MDBPageItem
                disabled={this.state.page == this.state.toVisualize.length - 1}
              >
                <MDBPageNav
                  className="page-link"
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
                    this.setState({
                      page: this.state.toVisualize.length - 1,
                    });
                  }}
                >
                  Last
                </MDBPageNav>
              </MDBPageItem>
            </MDBPagination>
          </Grid>
        </MDBContainer>
        <Footer />
      </div>
    );
  }

  setupPagination = (toPaginate) => {
    let pages = [];
    let collection = [];
    for (let i = 0; i < toPaginate.length; i++) {
      const element = toPaginate[i];
      collection.push(element);
      if (i > 0 && i % this.state.pageSize == 0) {
        pages.push(collection);
        collection = [];
      }
    }
    if (collection.length != 0) pages.push(collection);

    this.setState({ toVisualize: pages });
  };

  // TO REMOVE
  // gatherPagination = () => {
  //   this.setState({
  //     toVisualize: this.state.articles.slice(
  //       this.state.page * this.state.pageSize - this.state.pageSize,
  //       this.state.page * this.state.pageSize
  //     ),
  //   });
  // };

  searchByName = (text) => {
    console.log(text);
    let results = [];

    if (text.length > 0) {
      results = this.state.articles.filter((X) =>
        X.title.toLowerCase().includes(text.toLowerCase())
      );
    } else {
      results = this.state.articles;
    }
    this.setupPagination(results.length > 0 ? results : this.state.articles);
  };
}

export default ArticlesList;

// <MDBCard
//   key={article.id}
//   style={{
//     width: "18rem",
//     heigth: "200px",
//     minHeigth: "200px",
//     minHeigth: "200px",
//     margin: 20,
//   }}
// >
//   <MDBView hover zoom>
//     <MDBCardImage
//       className="img-fluid"
//       variant="top"
//       src={article.picture}
//       style={{
//         maxHeight: "200px",
//         width: "100%",
//         objectFit: "cover",
//       }}
//     />
//   </MDBView>
//   <MDBCardBody>
//     <MDBCardTitle>
//       <MDBRow>
//         <MDBCol>
//           <Link to={"article/" + article.id}>
//             {article.title}
//           </Link>
//         </MDBCol>

//         {this.state.isAdmin && (
//           <MDBCol md="auto">
//             <MDBBtn
//               variant="danger"
//               onClick={async () => {
//                 this.setState({ loading: true });
//                 firebaseSvc.removeArticle(article.id);
//                 let articles = await firebaseSvc.getLatestArticles(
//                   9999
//                 );
//                 this.setState({
//                   articles: articles,
//                 });
//                 this.setState({ loading: false });
//               }}
//             >
//               X
//             </MDBBtn>
//           </MDBCol>
//         )}
//       </MDBRow>
//     </MDBCardTitle>
//     {/* <MDBCard.Text>{article.content}</MDBCard.Text> */}
//   </MDBCardBody>
// </MDBCard>
