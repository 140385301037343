import React, { Component } from "react";
import FittedImage from "react-fitted-image";

import { EditorState, convertToRaw, ContentState } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import NavBar from "../components/elements/NavBar";
import Footer from "../components/elements/Footer";
import LoadingScreen from "../components/LoadingScreen";
import ToastCmp from "../components/elements/ToastCmp";

import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBBtn } from "mdbreact";
import { IconButton, Button, Paper } from "@material-ui/core";
import Checkbox from "rc-checkbox";

import firebaseSvc from "../firebaseSvc";
import { withRouter } from "react-router-dom";

import Fullscreen from "react-full-screen";

//styling
import "../App.css";
import "rc-checkbox/assets/index.css";

class ArticleScreenEditor extends Component {
  state = {
    articleID: null,
    articleRoomIDs: [],
    articlePicture: null,
    articleTitle: null,
    articleContent: null,
    roomsList: [],
    selectedRooms: [],
    loading: false,
    editorState: EditorState.createEmpty(),
    isFull: false,
    articleSaved: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });

    //switch to logout version if needed

    let roomsLst = await firebaseSvc.getRooms();

    roomsLst = roomsLst.sort((a, b) => {
      var nameA = a.name.toUpperCase(); // ignore upper and lowercase
      var nameB = b.name.toUpperCase(); // ignore upper and lowercase
      if (nameA <= nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
    });

    this.setState({ roomsList: roomsLst });

    let createNew = false;
    let articleID = this.props.match.params.articleID; //preso da dentro APP.js e passato alla rotta che porta agli articoli
    console.log("Article id is " + articleID);

    this.setState({ articleID: articleID });

    if (articleID != null) {
      let res = await firebaseSvc.getArticleFromID(articleID);
      if (res == null) {
        console.log("could not find article");
        createNew = true;
      } else {
        console.log("found article");
        const contentBlock = htmlToDraft(res.content);
        if (contentBlock) {
          const contentState = ContentState.createFromBlockArray(
            contentBlock.contentBlocks
          );
          const editorState = EditorState.createWithContent(contentState);
          this.setState({ editorState });
        }

        this.setState({ articleTitle: res.title });
        this.setState({
          articleContent: res.content,
        });
        this.setState({
          articlePicture: res.picture,
        });
        this.setState({ selectedRooms: res.rooms || [] });
      }
    } else {
      createNew = true;
    }
    if (createNew) {
      console.log("creating new article!");
      articleID = await firebaseSvc.createArticle({
        title: "Nuovo articolo",
        content: "<p>Inserisci qui la descrizione dell' articolo<p>",
        picture:
          "https://p1.pxfuel.com/preview/737/222/344/damask-pattern-victorian-vintage-royalty-free-thumbnail.jpg",
        articleRoomIDs: [],
      });

      console.log("New article ID is " + articleID);

      this.setState({ articleID: articleID });
      this.setState({ articleTitle: "Nuovo articolo" });
      this.setState({
        articleContent: "<p>Inserisci qui la descrizione dell' articolo<p>",
      });
      this.setState({
        articlePicture:
          "https://p1.pxfuel.com/preview/737/222/344/damask-pattern-victorian-vintage-royalty-free-thumbnail.jpg",
      });
    }

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <React.Fragment>
        <NavBar />
        <ToastCmp message="Article Saved" open={this.state.articleSaved} />
        <MDBContainer className="mb-5">
          {/* Save */}
          <div
            style={{
              position: "fixed",
              bottom: "2rem",
              right: "2rem",
              backgroundColor: "#97ff6e",
              borderRadius: "50%",
            }}
          >
            <IconButton variant="success" onClick={this.saveArticle}>
              <svg
                stroke="currentColor"
                fill="none"
                strokeWidth="2"
                viewBox="0 0 24 24"
                strokeLinecap="round"
                strokeLinejoin="round"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>
            </IconButton>
          </div>
          {/* New article */}
          <div
            style={{
              position: "fixed",
              bottom: "2rem",
              right: "7rem",
              backgroundColor: "#77d5d9",
              borderRadius: "50%",
            }}
          >
            <IconButton
              variant="success"
              onClick={() => {
                this.props.history.push("/article/new");
                window.location.reload(false);
              }}
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth="0"
                viewBox="0 0 1024 1024"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M696 480H544V328c0-4.4-3.6-8-8-8h-48c-4.4 0-8 3.6-8 8v152H328c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h152v152c0 4.4 3.6 8 8 8h48c4.4 0 8-3.6 8-8V544h152c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8z"></path>
                <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm0 820c-205.4 0-372-166.6-372-372s166.6-372 372-372 372 166.6 372 372-166.6 372-372 372z"></path>
              </svg>
            </IconButton>
          </div>
          <MDBRow>
            <input
              type="text"
              value={this.state.articleTitle}
              onChange={this.setName}
              className="display-4 w-100 mt-3"
              style={{ textAlign: "center" }}
            />
          </MDBRow>
          <MDBRow className="mt-md-4 mb-1" style={{ height: 400 }}>
            <MDBCol lg="9" style={{ height: "100%" }}>
              {this.state.articlePicture && (
                <FittedImage src={this.state.articlePicture} fit="cover" />
              )}
            </MDBCol>
            <MDBCol>
              <input
                type="file"
                className="custom-file-input"
                id="inputGroupFile01"
                aria-describedby="inputGroupFileAddon01"
                onChange={this.handleImageUpload}
              />
              <label className="btn btn-secondary" htmlFor="inputGroupFile01">
                Carica Immagine
              </label>
              <MDBCard>
                <form style={{ height: "300px", overflow: "auto" }}>
                  {this.state.roomsList != null &&
                    this.state.roomsList.map((room) => (
                      <div key={room.id} className="mb-3" id={room.id}>
                        <div className="custom-control custom-checkbox">
                          <label>
                            <Checkbox
                              onChange={(e) => this.handleCheckedRoom(e, room)}
                              checked={
                                this.state.selectedRooms != null &&
                                this.state.selectedRooms.includes(room.id)
                              }
                            />
                            {" - " + room.name}
                          </label>
                        </div>
                      </div>
                    ))}
                </form>
              </MDBCard>
            </MDBCol>
          </MDBRow>
          <Button
            style={{ margin: "2rem 0" }}
            onClick={() => this.setState({ isFull: true })}
          >
            Go Fullscreen
          </Button>
          <Fullscreen
            enabled={this.state.isFull}
            onChange={(isFull) => this.setState({ isFull })}
            style={{ padding: "4rem" }}
          >
            <Paper style={{ padding: this.state.isFull ? "3rem" : "0" }}>
              <Editor
                editorStyle={{
                  maxHeight: this.state.isFull ? "100vh" : "78vh",
                  minHeight: this.state.isFull ? "100vh" : "78vh",
                  scroll: "auto",
                  overflow: "auto",
                  border: "",
                }}
                toolbarHidden={false}
                ReadOnly={false}
                toolbar={{
                  image: {
                    uploadEnabled: true,
                    previewImage: true,
                  },
                  fontFamily: {
                    options: [
                      "Roboto",
                      "Arial",
                      "Georgia",
                      "Impact",
                      "Tahoma",
                      "Times New Roman",
                      "Verdana",
                      "Allan",
                      "Vast Shadow",
                      "Risque",
                      "Ribeye",
                      "Ribeye Marrow",
                      "Notable",
                      "Gorditas",
                      "Kranky",
                      "Mina",
                    ],
                  },
                }}
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                editorState={this.state.editorState}
                onEditorStateChange={(editorState) => {
                  console.log(editorState.getCurrentContent().getBlockMap());
                  this.handleEditorChange(editorState);
                }}
              />
            </Paper>
          </Fullscreen>
        </MDBContainer>
        <Footer />
      </React.Fragment>
    );
  }

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
    this.setState({
      articleContent: draftToHtml(
        convertToRaw(editorState.getCurrentContent())
      ),
    });
  };

  setName = (e) => {
    let nam = e.target.value;
    this.setState({ articleTitle: nam });
  };

  saveArticle = async () => {
    // this.setState({ loading: true });

    await firebaseSvc.updateField(
      "Articles",
      this.state.articleID,
      "title",
      this.state.articleTitle
    );

    await firebaseSvc.updateField(
      "Articles",
      this.state.articleID,
      "content",
      this.state.articleContent
    );

    await firebaseSvc.updateField(
      "Articles",
      this.state.articleID,
      "rooms",
      this.state.selectedRooms
    );
    //set up on upload of image
    // firebaseSvc.updateField(
    //   "Articles",
    //   this.state.articleID,
    //   "picture",
    //   this.state.articlePicture
    // );

    //TODO: HANDLE ROOM ID LIST
    this.setState({ articleSaved: true });
    setTimeout(() => this.setState({ articleSaved: false }), 3000);

    // this.setState({ loading: false });
  };

  handleImageUpload = async (e) => {
    if (e.target.files[0]) {
      this.setState({ loading: true });
      this.setState({ articlePicture: null });
      const image = e.target.files[0];
      console.log("uploading for " + this.state.articleID);
      await firebaseSvc.handleImageUpload(
        image,
        this.state.articleID,
        "Articles",
        this.state.articleID,
        this.onImageUploaded
      );
    }
  };

  onImageUploaded = async (url) => {
    console.log("setting image on db");
    let tPic = await firebaseSvc.getField(
      "Articles",
      this.state.articleID,
      "picture"
    );
    console.log(url);
    this.setState({
      articlePicture: url,
    });

    this.setState({ loading: false });
  };

  handleCheckedRoom = (e, room) => {
    let checked = e.target.checked;
    if (checked) {
      if (!this.state.selectedRooms.includes(room.id)) {
        let rooms = this.state.selectedRooms;
        rooms.push(room.id);
        this.setState({ selectedRooms: rooms });
      }
    } else {
      let rooms = this.state.selectedRooms.filter(
        (roomID) => roomID != room.id
      );
      this.setState({ selectedRooms: rooms });
    }
  };
}

export default withRouter(ArticleScreenEditor);
