import React, { Component, View, Text, Image } from "react";
import { Link } from "react-router-dom";
import FittedImage from "react-fitted-image";
import convert from "htmr";

import stylingConstants from "../stylingConstants";
import GridGenerator from "./GridGenerator";

import NavBar from "./elements/NavBar";
import Footer from "./elements/Footer";
import LoadingScreen from "./LoadingScreen";

import {
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardImage,
  MDBCardBody,
  MDBCardTitle,
  MDBBtn,
  MDBAnimation,
} from "mdbreact";
import { IconButton } from "@material-ui/core";

import cookie from "react-cookies";
import firebaseSvc from "../firebaseSvc";

class RoomsList extends Component {
  state = {
    Rooms: [],
    loading: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });

    this.setState({ isAdmin: cookie.load("userID") != null });

    //list all Rooms
    let rooms = await firebaseSvc.getRooms();
    //console.log(rooms);
    rooms = rooms.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    //console.log(rooms);
    this.setState({ Rooms: rooms });

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <NavBar />
        <MDBContainer>
          {this.state.isAdmin && (
            <MDBBtn
              onClick={() => this.props.history.push("/room/new")}
              className="list-group-item list-group-item-action list-group-item-success"
              style={{ margin: "20px" }}
            >
              Crea una nuova stanza
            </MDBBtn>
          )}
          <GridGenerator cols={3}>
            {this.state.Rooms.map((room, index) => (
              <MDBAnimation
                type="fadeInUp"
                delay={index * 100 + "ms"}
                duration="700ms"
                key={index}
              >
                <MDBCard
                  style={{ width: "20rem", heigth: "200px", margin: 20 }}
                >
                  <MDBCardImage
                    variant="top"
                    src={room.picture}
                    style={{
                      minHeight: "200px",
                      maxHeight: "200px",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <MDBCardBody style={{ height: "100px" }}>
                    <MDBCardTitle>
                      <MDBRow>
                        <MDBCol>
                          <Link to={"room/" + room.id}>
                            {room.name.slice(3, 999)}
                          </Link>
                        </MDBCol>
                        <MDBCol md="auto">
                          {this.state.isAdmin && (
                            <IconButton
                              variant="outlined"
                              style={{
                                backgroundColor: "#ed2f2f",
                              }}
                              onClick={() => this.deleteRoom(room.id)}
                            >
                              <svg
                                stroke="white"
                                fill="white"
                                strokeWidth="0"
                                viewBox="0 0 24 24"
                                height="1rem"
                                width="1rem"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path fill="none" d="M0 0h24v24H0z"></path>
                                  <path d="M7 4V2h10v2h5v2h-2v15a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V6H2V4h5zM6 6v14h12V6H6zm3 3h2v8H9V9zm4 0h2v8h-2V9z"></path>
                                </g>
                              </svg>
                            </IconButton>
                          )}
                        </MDBCol>
                      </MDBRow>
                    </MDBCardTitle>
                    {/* <MDBCard.Text>{room.content}</MDBCard.Text> */}
                  </MDBCardBody>
                </MDBCard>
              </MDBAnimation>
            ))}
          </GridGenerator>
          <br />
        </MDBContainer>
        <Footer />
      </div>
    );
  }

  deleteRoom = async (ID) => {
    this.setState({ loading: true });
    await firebaseSvc.deleteRoomWithID(ID);
    let rooms = await firebaseSvc.getRooms();
    //console.log(rooms);
    rooms = rooms.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    //console.log(rooms);
    this.setState({ Rooms: rooms });
    this.setState({ loading: false });
  };
}

export default RoomsList;
