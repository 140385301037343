import { chunk } from "lodash";
import * as React from "react";
import { MDBCol, MDBRow } from "mdbreact";
const GridGeneratorProps = {
  cols: 1 | 2 | 3 | 4 | 6 | 12,
};
const GridGenerator = ({ cols, children }) => {
  const colWidth = 12 / cols;
  const rows = chunk(React.Children.toArray(children), cols);
  var id = 0;
  return (
    <React.Fragment>
      {rows.map((cols, index) => {
        return (
          <MDBRow key={index}>
            {cols.map((col, ind) => (
              <MDBCol key={index + ind} size="12" md={colWidth.toString()}>
                {col}
              </MDBCol>
            ))}
          </MDBRow>
        );
      })}
    </React.Fragment>
  );
};
export default GridGenerator;
