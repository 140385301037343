import React, { Component } from "react";

import {
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarNav,
  MDBNavItem,
  MDBNavLink,
  MDBNavbarToggler,
  MDBCollapse,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
  MDBStickyContent,
  MDBSticky,
  MDBBtn,
} from "mdbreact";
import IconButton from "@material-ui/core/IconButton";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";

import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";

import { withRouter } from "react-router-dom";
import styleSettings from "../../stylingConstants";
import Sticky from "react-stickynode";

import cookie from "react-cookies";
import firebaseSvc from "../../firebaseSvc";

class NavBar extends Component {
  state = {
    isAdmin: false,
    rooms: [],
    isOpen: false,
  };

  componentDidMount = async () => {
    this.setState({ isAdmin: cookie.load("userID") != null });
    let rooms = await firebaseSvc.getRooms();
    rooms = rooms.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    this.setState({ rooms });
  };

  toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    return (
      <Sticky top={0} innerZ={100}>
        <MDBNavbar color="sunny-morning-gradient" light expand="md">
          <MDBNavbarBrand style={styleSettings.navTitleStyle}>
            <MDBNavLink to="/">
              <strong className="white-text">Home</strong>
            </MDBNavLink>
          </MDBNavbarBrand>
          <MDBNavbarToggler onClick={this.toggleCollapse} />
          <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
            <MDBNavbarNav left>
              <MDBNavLink to="/articles" style={styleSettings.navTitleStyle}>
                <b>Blog</b>
              </MDBNavLink>
              <MDBDropdown>
                <MDBDropdownToggle nav style={styleSettings.navTitleStyle}>
                  <div style={styleSettings.navTitleStyle}>
                    <b>{firebaseSvc.namesMap[firebaseSvc.language].rooms}</b>
                  </div>
                </MDBDropdownToggle>
                <MDBDropdownMenu className="dropdown-default">
                  <MDBDropdownItem
                    active
                    onClick={() => this.props.history.push("/rooms")}
                  >
                    {firebaseSvc.language == "ita"
                      ? "Tutte Le stanze"
                      : "Explore Rooms"}
                  </MDBDropdownItem>
                  <MDBDropdownItem divider />
                  {this.state.rooms.map((room, index) => (
                    <MDBDropdownItem
                      key={index}
                      onClick={() => {
                        this.props.history.push("/room/" + room.id);
                        firebaseSvc.activePage &&
                          firebaseSvc.activePage.refresh();
                      }}
                    >
                      {room.name.slice(3, 999)}
                    </MDBDropdownItem>
                  ))}
                </MDBDropdownMenu>
              </MDBDropdown>
              <MDBNavLink to="/events" style={styleSettings.navTitleStyle}>
                <b>{firebaseSvc.language == "ita" ? "Eventi" : "Events"}</b>
              </MDBNavLink>
              <MDBNavLink
                to="/links"
                style={styleSettings.navTitleStyle}
                onClick={() => {
                  this.props.history.push("/links", {
                    state: { visualizationType: "list-all" },
                  });
                  firebaseSvc.activePage && firebaseSvc.activePage.refresh();
                }}
              >
                <b>Links</b>
              </MDBNavLink>
              <MDBNavLink to="/about" style={styleSettings.navTitleStyle}>
                <b>{firebaseSvc.language == "ita" ? "Su di me" : "About Me"}</b>
              </MDBNavLink>
              <MDBNavLink to="/contacts" style={styleSettings.navTitleStyle}>
                <b>{firebaseSvc.language == "ita" ? "Contatti" : "Contacts"}</b>
              </MDBNavLink>
            </MDBNavbarNav>
            <MDBNavbarNav right>
              <Grid container justify="space-around">
                <Link
                  href="https://www.youtube.com/channel/UCFYPRWNB8NZlgVMR7A2KkhQ"
                  target="blank"
                >
                  <IconButton
                    size="medium"
                    color="secondary"
                    aria-label="youtube"
                  >
                    <MDBIcon fontSize="large" fab icon="youtube" />
                  </IconButton>
                </Link>
                <Link
                  href="https://www.instagram.com/psichiatriatranspersonale/"
                  target="blank"
                >
                  <IconButton
                    size="medium"
                    color="default"
                    aria-label="instagram"
                  >
                    <MDBIcon fontSize="large" fab icon="instagram" />
                  </IconButton>
                </Link>
                <Link
                  href="https://www.facebook.com/claudio.maddaloni53"
                  target="blank"
                >
                  <IconButton
                    size="medium"
                    color="primary"
                    aria-label="facebook"
                  >
                    <MDBIcon fontSize="large" fab icon="facebook-square" />
                  </IconButton>
                </Link>
              </Grid>
              <Grid item>
                <MDBNavLink to="/landing" style={styleSettings.navTitleStyle}>
                  <b>{firebaseSvc.language == "ita" ? "Lingua" : "Language"}</b>
                </MDBNavLink>
              </Grid>

              <Grid item>
                {/* LOGIN BUTTON */}
                {!this.state.isAdmin && (
                  <MDBIcon
                    fab
                    icon="expeditedssl"
                    onClick={() => {
                      firebaseSvc.lastPage = this.props.location.pathname;
                      this.props.history.push("/admin");
                    }}
                  />
                )}
                {/* LOGOUT BUTTON */}
                {this.state.isAdmin && (
                  <IconButton
                    color="secondary"
                    size="medium"
                    onClick={async () => {
                      await firebaseSvc.doSignOut();
                      if (this.props.onLogout != null) this.props.onLogout();
                      this.props.history.push(this.props.location.pathname);
                      window.location.reload(false);
                    }}
                  >
                    <ExitToAppIcon />
                  </IconButton>
                )}
              </Grid>
            </MDBNavbarNav>
          </MDBCollapse>
        </MDBNavbar>
      </Sticky>
    );
  }
}

export default withRouter(NavBar);
