import React, { Component } from "react";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

class LoadingScreen extends Component {
  state = {};
  render() {
    return (
      <MDBContainer
        style={{
          height: "100vh",
          display: "flex",
        }}
      >
        <MDBRow
          style={{
            justifyContent: "center",
            alignItems: "center",
            flex: 1,
          }}
        >
          <MDBCol
            style={{ justifyContent: "center", flex: 1, display: "flex" }}
          >
            <div className="spinner-grow text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </MDBCol>
        </MDBRow>
      </MDBContainer>
    );
  }
}

export default LoadingScreen;
