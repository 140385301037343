import React, { Component, View, Text, Image } from "react";
import cookie from "react-cookies";

import Typography from "@material-ui/core/Typography";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Divider from "@material-ui/core/Divider";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import LoadingScreen from "../LoadingScreen";

import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBTable,
  MDBTableHead,
  MDBInput,
  MDBTableBody,
  MDBIcon,
  MDBBtn,
  MDBAnimation,
} from "mdbreact";

import firebaseSvc from "../../firebaseSvc";

import NavBar from "../elements/NavBar";
import { withRouter } from "react-router-dom";

class LinksScreen extends Component {
  state = {
    visualizationType: "",
    linksList: [],
    categoriesList: [],
    structuredData: [],
    isAdmin: false,
    newCatName: "",
    loading: false,
  };

  componentDidMount = async () => {
    firebaseSvc.activePage = this;
    await this.refresh();
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <div
        style={{
          backgroundColor: "#f5f5f5",
          height: "100%",
          minHeight: "100vh",
        }}
      >
        <NavBar />
        <MDBContainer>
          {this.state.isAdmin && this.renderAdminControls()}
          {this.state.isAdmin && <Divider />}

          {this.state.visualizationType == "list-all" && (
            <div className="clearfix mt-5">
              {this.state.structuredData.map((cat, i) => (
                <MDBAnimation
                  key={i}
                  type="fadeIn"
                  duration="700ms"
                  delay={i * 50 + "ms"}
                >
                  {this.renderCategoryOfLinks(cat)}
                </MDBAnimation>
              ))}
            </div>
          )}
          {this.state.visualizationType == "list-room" &&
            this.state.linksList.length > 0 && (
              <div className="clearfix mt-5">
                <MDBAnimation type="fadeIn" duration="700ms" delay={50 + "ms"}>
                  {this.renderAllLinks()}
                </MDBAnimation>
              </div>
            )}
        </MDBContainer>
      </div>
    );
  }

  renderAdminControls = () => (
    <div style={{ margin: "15px" }}>
      <Grid
        container
        direction="row"
        justify="space-around"
        alignItems="center"
      >
        <Grid item>
          <TextField
            id="standard-basic"
            label="Nome Categoria"
            value={this.state.newCatName}
            onChange={(e) => {
              this.setState({ newCatName: e.target.value });
            }}
          />
          <Button
            color="primary"
            onClick={() => {
              if (this.state.newCatName.length > 0) {
                firebaseSvc.createCategory({
                  title: this.state.newCatName,
                });
                this.setState({ newCatName: "" });
                this.refresh();
              }
            }}
          >
            Salva
          </Button>
        </Grid>
        <Grid>
          <Button
            color="primary"
            onClick={() =>
              this.props.history.push("/links/editor", {
                from: "/links",
              })
            }
          >
            New Link
          </Button>
        </Grid>
      </Grid>
    </div>
  );

  renderCategoryOfLinks = (categoryStructured) => {
    //console.log(categoryStructured);
    return (
      <div key={categoryStructured.category} className="m-2 mx-5">
        <ExpansionPanel
          square
          elevation={0}
          style={{ backgroundColor: "#f5f5f5" }}
        >
          <ExpansionPanelSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="h5" color="textPrimary">
              {categoryStructured.category}
            </Typography>
          </ExpansionPanelSummary>

          <MDBTable hover small style={{ width: "100%" }}>
            {categoryStructured.links.map((linksCat, i) => (
              <MDBAnimation
                key={i}
                type="fadeIn"
                duration="700ms"
                delay={i * 50 + "ms"}
              >
                <ExpansionPanelDetails
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "stretch",
                  }}
                >
                  {!this.state.isAdmin && (
                    <MDBTableBody style={{ width: "100%" }}>
                      <a
                        href={linksCat.url}
                        target="_blank"
                        style={{
                          paddingLeft: "50px",
                          width: "100%",
                          display: "flex",
                          flex: 1,
                          flexDirection: "row",
                        }}
                      >
                        <tr
                          key={linksCat.id}
                          style={{
                            width: "100%",
                            display: "flex",
                            flex: 1,
                            flexDirection: "row",
                          }}
                        >
                          <td style={{ width: "170px" }}>
                            <strong>{linksCat.title}</strong>
                          </td>
                          <td style={{ display: "flex", flexGrow: 2 }}>
                            {linksCat.description}
                          </td>
                        </tr>
                      </a>
                    </MDBTableBody>
                  )}
                  {this.state.isAdmin && (
                    <MDBTable responsive small style={{ width: "100%" }}>
                      <MDBTableBody style={{ width: "100%" }}>
                        <tr key={linksCat.id}>
                          <td style={{ width: "190px" }}>{linksCat.title}</td>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <td style={{ width: "100%" }}>
                              {linksCat.description}
                            </td>
                            <div>
                              <td>
                                <MDBIcon
                                  far
                                  icon="edit"
                                  onClick={() =>
                                    this.props.history.push("/links/editor", {
                                      from: "/links",
                                      linkID: linksCat.id,
                                    })
                                  }
                                />
                              </td>
                              <td>
                                <MDBIcon
                                  icon="trash"
                                  onClick={async () => {
                                    this.setState({ loading: true });
                                    await firebaseSvc.removeLink(linksCat.id);
                                    await this.refresh();
                                    this.setState({ loading: false });
                                  }}
                                />
                              </td>
                            </div>
                          </div>
                        </tr>
                      </MDBTableBody>
                    </MDBTable>
                  )}
                </ExpansionPanelDetails>
              </MDBAnimation>
            ))}
          </MDBTable>
        </ExpansionPanel>
      </div>
    );
  };

  renderAllLinks = () => {
    return (
      <MDBTable hover small style={{ width: "100%" }}>
        {this.state.linksList.map((linksCat, i) => (
          <MDBAnimation
            key={i}
            type="fadeIn"
            duration="700ms"
            delay={i * 50 + "ms"}
          >
            <ExpansionPanelDetails
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "stretch",
              }}
            >
              <MDBTableBody style={{ width: "100%" }}>
                <a
                  href={linksCat.url}
                  target="_blank"
                  style={{
                    paddingLeft: "50px",
                    width: "100%",
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                  }}
                >
                  <tr
                    key={linksCat.id}
                    style={{
                      width: "100%",
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                    }}
                  >
                    <td style={{ width: "170px" }}>
                      <strong>{linksCat.title}</strong>
                    </td>
                    <td style={{ display: "flex", flexGrow: 2 }}>
                      {linksCat.description}
                    </td>
                  </tr>
                </a>
              </MDBTableBody>
            </ExpansionPanelDetails>
          </MDBAnimation>
        ))}
      </MDBTable>
    );
  };

  refresh = async () => {
    this.setState({ isAdmin: cookie.load("userID") != null });

    this.setState({ loading: true });

    let roomID = null;
    let vizType = "list-all";
    if (this.props.location.state != null) {
      roomID = this.props.location.state.roomID;
      if (roomID != null) vizType = "list-room";
    }

    this.setState({ visualizationType: vizType });

    // //set data
    if (vizType == "list-all") {
      /**
       * [
       *    {
       *        catogory : 'test'
       *        links : [{link}]
       *    },
       * ]
       */

      let structuredData = [];
      let categories = await firebaseSvc.getCategories();
      //console.log(categories);
      let links = await firebaseSvc.getLinks();

      //SORT
      categories = categories.sort((a, b) => {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA <= nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
      });
      //CLEAN
      categories.forEach((cat) => {
        if (cat.name.includes("@")) {
          cat.name = cat.name.split("@")[1];
        }
      });

      this.setState({ categoriesList: categories });

      //create structured data for the table
      categories.forEach((cat) => {
        let linksInCategory = links.filter((link) =>
          link.category.includes(cat.id)
        );

        linksInCategory = linksInCategory.reverse();

        structuredData.push({
          category: cat.name,
          links: linksInCategory,
        });
      });

      //set the state
      this.setState({ structuredData });
    } else if (vizType == "list-room") {
      let roomID = this.props.location.state.roomID;
      let linksInRoom = await firebaseSvc.getLinksInRoom(roomID);
      if (roomID != null) this.setState({ linksList: linksInRoom });
      else console.log("Pass the Room ID, please");
      //clean up
      this.props.location.state.roomID = null;
    }

    this.setState({ loading: false });
  };
}

export default withRouter(LinksScreen);
