import React, { Component, View, Text, Image } from "react";
import "../App.css";

import NavBar from "../components/elements/NavBar";
import Footer from "../components/elements/Footer";
import StaticPage from "./elements/StaticPageTemplate";
import SideNav from "../components/SideNavigation";
import ArticlesCarousel from "./elements/ArticlesCarousel";

import cookie from "react-cookies";
import { MDBRow, MDBCol } from "mdbreact";

import firebaseSvc from "../firebaseSvc";

import Logo from "../assets/title.png";
import LogoEng from "../assets/title_eng.png";

class HomeScreen extends Component {
  state = {
    isLoggedIn: false,
  };
  componentWillMount = async () => {
    this.setState({ isLoggedIn: firebaseSvc.checkLogin() });
  };

  componentDidMount = async () => {
    let language = cookie.load("language", null);
    console.log(language);
    if (language == null) {
      this.props.history.push("/landing");
      return;
    }
  };

  render() {
    return (
      <div
        style={{
          backgroundColor: "#F5F5F5",
        }}
      >
        <NavBar />
        <MDBRow
          center
          style={{
            backgroundColor: "#F5F5F5",
            maxWidth: "100%",
            minWidth: "100%",
          }}
        >
          <MDBCol sm="auto" lg="10">
            <MDBRow center sm="12" md="8">
              <img
                src={firebaseSvc.language == "ita" ? Logo : LogoEng}
                style={{
                  marginTop: "50px",
                  width: "50%",
                }}
              />
            </MDBRow>
            <MDBRow>
              <MDBCol lg="8" style={{ padding: "20px" }}>
                <StaticPage
                  // title={firebaseSvc.language == "ita" ? "Eventi" : "Events"}
                  dbURL="StaticPages/Home"
                  disabled={!this.state.isLoggedIn}
                />
              </MDBCol>
              <MDBCol lg="4" className="mt-5">
                <ArticlesCarousel />
              </MDBCol>
            </MDBRow>
          </MDBCol>
        </MDBRow>
        <Footer />
      </div>
    );
  }
}

export default HomeScreen;
