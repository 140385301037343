import React, { Component } from "react";

import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

import NavBar from "./elements/NavBar";
//sections
import Title from "./elements/Title";
import BaseLayout from "./elements/BaseLayout";

import Footer from "./elements/Footer";
import LoadingScreen from "./LoadingScreen";

import { MDBContainer, MDBMedia, MDBRow, MDBAnimation } from "mdbreact";

import firebaseSvc from "../firebaseSvc";

import { withRouter } from "react-router-dom";

//styling
import "../App.css";
import "rc-checkbox/assets/index.css";

class ArticleScreen extends Component {
  state = {
    articleDetails: null,
    editorState: EditorState.createEmpty(),
    editorContent: null,
    connectedRooms: [],
    loading: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });

    let articleID = this.props.match.params.articleID;

    this.setState({ articleID });

    let article = await firebaseSvc.getArticleFromID(articleID);
    const contentBlock = htmlToDraft(article.content);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      const editorState = EditorState.createWithContent(contentState);
      this.setState({ editorState, editorContent: article.content });
    }

    this.setState({ articleDetails: article });

    //fetch rooms
    let assRooms = [];
    if (article.rooms != null) {
      for (let i = 0; i < article.rooms.length; i++) {
        const room = article.rooms[i];
        let roomDeets = await firebaseSvc.getRoomFromID(room);
        if (roomDeets != null) {
          assRooms.push({
            id: room,
            name: roomDeets.name,
            picture: roomDeets.picture,
          }); //create room object
        }
      }
    }
    this.setState({ connectedRooms: assRooms });

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <NavBar />
        <MDBContainer>
          <MDBAnimation type="fadeIn" duration="700ms">
            <Title
              title={this.state.articleDetails.title}
              editable={false}
              onChange={(title) => {
                let deets = this.state.articleDetails;
                deets.title = title;
                this.setState({ articleDetails: deets });
              }}
            />
          </MDBAnimation>
          <MDBRow className="d-flex d-block justify-content-center">
            <BaseLayout
              round={true}
              disabled={true}
              imageURL={this.state.articleDetails.picture}
              content={this.state.editorContent}
              onChange={(imageURL, htmlContent) => {
                console.log(imageURL, htmlContent);
              }}
              handleImageUpload={this.handleImageUpload}
            />
          </MDBRow>
          {this.state.connectedRooms.length > 0 && (
            <React.Fragment>
              <h1 className="mb-5">
                <u>Stanze collegate:</u>
              </h1>
              <MDBContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ul className="list-unstyled">
                  {this.state.connectedRooms.map((room) => (
                    <div key={room.id}>
                      <MDBMedia as="li">
                        <img
                          style={{
                            width: "64px",
                            height: "64px",
                          }}
                          src={room.picture}
                          className="rounded mr-3 mb-3 shadow img-thumbnail"
                        />
                        <MDBMedia
                          heading
                          style={{
                            alignItems: "center",
                          }}
                        >
                          <h3>
                            <a href={"/room/" + room.id}>{room.name}</a>
                          </h3>
                        </MDBMedia>
                      </MDBMedia>
                      <hr />
                    </div>
                  ))}
                </ul>
              </MDBContainer>
            </React.Fragment>
          )}
        </MDBContainer>
        <Footer />
      </div>
    );
  }

  handleImageUpload = async (e) => {
    if (e.target.files[0]) {
      this.setState({ loading: true });
      const image = e.target.files[0];
      console.log("uploading for " + this.state.articleID);
      await firebaseSvc.handleImageUpload(
        image,
        this.state.articleID,
        "Articles",
        this.state.articleID,
        this.onImageUploaded
      );
    }
  };

  onImageUploaded = async (url) => {
    console.log("setting image on db");
    await firebaseSvc.getField("Articles", this.state.articleID, "picture");

    let deets = this.state.articleDetails;
    deets.picture = url;

    this.setState({
      articleDeets: deets,
    });

    this.setState({ loading: false });
  };
}

export default withRouter(ArticleScreen);
