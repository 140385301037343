import React, { Component } from "react";

import { EditorState, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import htmlToDraft from "html-to-draftjs";

//sections
import Title from "./Title";
import BaseLayout from "./BaseLayout";

import Footer from "./Footer";
import LoadingScreen from "../LoadingScreen";
import ToastCmp from "./ToastCmp";

import { MDBContainer, MDBBtn, MDBRow } from "mdbreact";

import firebaseSvc from "../../firebaseSvc";

import { withRouter } from "react-router-dom";

class StaticPage extends Component {
  state = {
    editorState: EditorState.createEmpty(),
    dbRoute: null,
    pageTitle: null,
    pageContents: null,
    pagePicture: null,
    loading: true,
    editable: false,
    imageIsRound: false,
    pageSaved: false,
  };

  componentWillMount = async () => {
    //get props for the configuration
    const databaseURL = this.props.dbURL;
    const pageTitle = this.props.title;
    const isEditable = !this.props.disabled;
    const imageIsRound = this.props.round;
    this.setState({ imageIsRound });
    this.setState({ editable: isEditable });
    this.setState({ pageTitle });

    //load stuff
    this.setState({ loading: true });

    let dbRoute = databaseURL.split("/");

    let imgName =
      dbRoute[1] == "Home" ? dbRoute[1] + firebaseSvc.language : dbRoute[1];

    this.setState({ dbRoute });

    let picture = await firebaseSvc.getField(dbRoute[0], imgName, "picture");
    let data = await firebaseSvc.getField(dbRoute[0], imgName, "content");

    //console.log(picture);

    //content
    if (data == null) {
      const editorState = EditorState.createEmpty();
      this.setState({ editorState });
      this.setState({ pageContents: "<h1>Content not found</h1>" });
    } else {
      const contentBlock = htmlToDraft(data);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
        this.setState({ pageContents: data });
      }
    }

    //picture
    if (picture == null) {
      this.setState({
        pagePicture: "https://image.flaticon.com/icons/svg/1548/1548784.svg",
      });
    } else this.setState({ pagePicture: picture });

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <React.Fragment>
        <ToastCmp message="Article Saved" open={this.state.pageSaved} />
        <MDBContainer>
          {this.state.editable && (
            <MDBRow>
              <MDBBtn
                className="mt-2"
                variant="success"
                block
                onClick={this.savePage}
              >
                Salva
              </MDBBtn>
            </MDBRow>
          )}

          <Title
            title={this.state.pageTitle}
            editable={this.state.editable}
            onChange={(title) => {
              this.setState({ title });
            }}
          />
          <MDBRow className="d-flex d-block justify-content-center">
            <BaseLayout
              round={this.state.imageIsRound}
              disabled={!this.state.editable}
              imageURL={this.state.pagePicture}
              content={this.state.pageContents}
              onChange={(imageURL, htmlContent) => {
                this.setState({ pageContents: htmlContent });
              }}
              handleImageUpload={this.handleImageUpload}
            />
          </MDBRow>
        </MDBContainer>
        <Footer />
      </React.Fragment>
    );
  }

  handleImageUpload = async (e) => {
    if (e.target.files[0]) {
      this.setState({ loading: true });
      const image = e.target.files[0];

      let imgName =
        this.state.dbRoute[1] == "Home"
          ? this.state.dbRoute[1] + firebaseSvc.language
          : this.state.dbRoute[1];

      console.log("uploading for " + imgName);

      await firebaseSvc.handleImageUpload(
        image,
        imgName,
        this.state.dbRoute[0],
        imgName,
        this.onImageUploaded
      );
    }
  };

  onImageUploaded = async (url) => {
    console.log("setting image on db");
    console.log(this.state.dbRoute[1]);
    let imgName =
      this.state.dbRoute[1] == "Home"
        ? this.state.dbRoute[1] + firebaseSvc.language
        : this.state.dbRoute[1];
    await firebaseSvc.getField(this.state.dbRoute[0], imgName, "picture");

    this.setState({
      pagePicture: url,
    });

    this.setState({ loading: false });
  };

  savePage = async () => {
    // this.setState({ loading: true });
    let imgName =
      this.state.dbRoute[1] == "Home"
        ? this.state.dbRoute[1] + firebaseSvc.language
        : this.state.dbRoute[1];

    await firebaseSvc.updateField(
      this.state.dbRoute[0],
      imgName,
      "content",
      this.state.pageContents
    );

    this.setState({ pageSaved: true });
    setTimeout(() => this.setState({ pageSaved: false }), 3000);

    // this.setState({ loading: false });
  };
}

export default withRouter(StaticPage);
