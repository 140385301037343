import React, { Component, View, Text, Image } from "react";
import FittedImage from "react-fitted-image";

import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import NavBar from "../components/elements/NavBar";
import Footer from "../components/elements/Footer";
import LoadingScreen from "../components/LoadingScreen";

import { MDBContainer, MDBRow, MDBCol, MDBBtn } from "mdbreact";

import firebaseSvc from "../firebaseSvc";
import cookie from "react-cookies";
import { withRouter } from "react-router-dom";

import Fullscreen from "react-full-screen";

class RoomScreenEditor extends Component {
  state = {
    roomID: null,
    roomDetails: null,
    editorState: EditorState.createEmpty(),
    loading: false,
    isFull: false,
  };

  componentWillMount = async () => {
    firebaseSvc.activePage = this;
    await this.refresh();
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <React.Fragment>
        <NavBar />
        <MDBContainer>
          <MDBRow className="mt-2">
            <MDBBtn variant="success" block onClick={this.saveRoom}>
              Salva
            </MDBBtn>
          </MDBRow>
          <MDBRow>
            <input
              type="text"
              value={this.state.roomDetails.name}
              onChange={this.setName}
              className="display-4 w-100 mt-3"
              style={{ textAlign: "center" }}
            />
          </MDBRow>
          <MDBRow className="mt-md-4 mb-1" style={{ height: 400 }}>
            {this.state.roomDetails.picture && (
              <FittedImage src={this.state.roomDetails.picture} fit="cover" />
            )}
          </MDBRow>
          <MDBRow style={{ justifyContent: "center" }}>
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={this.handleImageUpload}
            />
            <label className="btn btn-secondary" htmlFor="inputGroupFile01">
              Carica Immagine
            </label>
          </MDBRow>
          <MDBRow className="pt-4">
            <MDBCol>
              <button onClick={() => this.setState({ isFull: true })}>
                Go Fullscreen
              </button>
              <Fullscreen
                enabled={this.state.isFull}
                onChange={(isFull) => this.setState({ isFull })}
              >
                <Editor
                  editorStyle={{
                    maxHeight: "800px",
                    minHeight: "800px",
                    scroll: "auto",
                    overflow: "auto",
                    border: "",
                  }}
                  toolbarHidden={false}
                  ReadOnly={false}
                  toolbar={{
                    image: {
                      uploadEnabled: true,
                      previewImage: true,
                    },
                  }}
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName"
                  editorState={this.state.editorState}
                  onEditorStateChange={(editorState) => {
                    console.log(editorState.getCurrentContent().getBlockMap());
                    this.handleEditorChange(editorState);
                  }}
                />
              </Fullscreen>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
        <Footer />
      </React.Fragment>
    );
  }

  refresh = async () => {
    this.setState({ loading: true });

    let createNew = false;
    let roomID = this.props.match.params.roomID;
    console.log("Room id is " + roomID);
    this.setState({ roomID: roomID });

    if (roomID != null) {
      let rd = await firebaseSvc.getRoomFromID(roomID);

      if (rd == null) {
        console.log("could not find room");
        createNew = true;
      } else {
        var contentBlock = null;

        if (rd.description.blocks) {
          const editorState = EditorState.createWithContent(
            convertFromRaw(rd.description)
          );
          this.setState({ editorState });
        } else {
          contentBlock = htmlToDraft(rd.description);

          if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(
              contentBlock.contentBlocks
            );
            const editorState = EditorState.createWithContent(contentState);
            this.setState({ editorState });
          }
        }
      }
    } else {
      createNew = true;
    }
    if (createNew) {
      console.log("creating new room!");
      roomID = await firebaseSvc.createRoom({
        name: "Nuova Stanza",
        description: "<p>Inserisci qui la descrizione della stanza<p>",
        picture:
          "https://p1.pxfuel.com/preview/737/222/344/damask-pattern-victorian-vintage-royalty-free-thumbnail.jpg",
      });
    }

    console.log("Created room " + roomID);
    let rd = await firebaseSvc.getRoomFromID(roomID);
    this.setState({ roomDetails: rd });
    this.setState({ roomID });
    this.setState({ loading: false });
  };

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
    let update = this.state.roomDetails;
    update.description = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    this.setState({ roomDetails: update });
  };

  setName = (e) => {
    let nam = e.target.value;
    let update = this.state.roomDetails;
    update.name = nam;
    this.setState({ roomDetails: update });
  };

  saveRoom = async () => {
    this.setState({ loading: true });

    firebaseSvc.updateField(
      "Rooms",
      this.state.roomID,
      "name",
      this.state.roomDetails.name
    );
    firebaseSvc.updateField(
      "Rooms",
      this.state.roomID,
      "description",
      this.state.roomDetails.description
    );
    firebaseSvc.updateField(
      "Rooms",
      this.state.roomID,
      "picture",
      this.state.roomDetails.picture
    );

    this.setState({ loading: false });
  };

  handleImageUpload = async (e) => {
    if (e.target.files[0]) {
      this.setState({ loading: true });
      this.setState({ picture: null });
      const image = e.target.files[0];
      await firebaseSvc.handleImageUpload(
        image,
        this.state.roomID,
        "Rooms",
        this.state.roomID,
        this.onImageUploaded
      );
    }
  };

  onImageUploaded = async (url) => {
    console.log("setting image on db");
    let tPic = await firebaseSvc.getField(
      "Articles",
      this.state.roomID,
      "picture"
    );

    //update room
    let tRoom = { ...this.state.roomDetails };
    tRoom.picture = url;
    this.setState({ roomDetails: tRoom });

    this.setState({ loading: false });
  };
}

export default withRouter(RoomScreenEditor);
