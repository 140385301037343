class StyleSettings {
  constructor() {
    this.brandColor = "#0784b5";

    this.navTitleStyle = {
      fontFamily: "lato",
      fontSize: 20,
      color: "black",
      margin: "0 5px",
    };

    this.navStyle = {
      backgroundColor: this.brandColor,
      marginBottom: "70px",
    };

    this.paragraphStyle = {
      fontFamily: "lato",
      fontSize: 16,
    };
  }
}

const styleSettings = new StyleSettings();
export default styleSettings;
