import React, { Component, View, Text, Image } from "react";
import FittedImage from "react-fitted-image";
import convert from "htmr";

import { withRouter } from "react-router-dom";

import stylingConstants from "../../stylingConstants";

import LoadingScreen from "../LoadingScreen";

import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBInput } from "mdbreact";
import Checkbox from "rc-checkbox";
import "rc-checkbox/assets/index.css";

import firebaseSvc from "../../firebaseSvc";

class LinkEditorCmp extends Component {
  state = {
    linkID: null,
    linkInfo: null,
    linkRooms: [],
    linkCategories: [],

    linkTitle: "Nome del link",
    linkURL: "URL del link",
    linkDescription: "Descrizione del link",
    linkSelectedRooms: [],
    linkSelectedCategories: [],
    from: "",
    loading: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });

    this.setState({ from: this.props.location.state.from });

    //set data
    let id = this.props.location.state.linkID;
    if (id != null) {
      let linkInfo = await firebaseSvc.getLinkFromID(id);

      this.setState({ linkID: id });
      this.setState({ linkInfo });

      this.setState({
        linkTitle: linkInfo.title,
        linkURL: linkInfo.url,
        linkDescription: linkInfo.description,
        linkSelectedRooms: linkInfo.room,
        linkSelectedCategories: linkInfo.category,
      });
    }

    this.setState({ linkRooms: await firebaseSvc.getRooms() });
    this.setState({ linkCategories: await firebaseSvc.getCategories() });

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <React.Fragment>
        <MDBContainer
          style={{
            padding: "20px",
            display: "flex",
            flex: 1,
            flexDirection: "MDBrow",
            justifyContent: "center",
            alignItems: "center",
            height: "90vh",
          }}
        >
          <form style={{ height: "100%" }}>
            <MDBBtn
              size="sm"
              variant="success"
              className="btn-block"
              onClick={this.saveLink}
            >
              Salva
            </MDBBtn>
            <hr />
            <MDBRow>
              <MDBCol>
                <h3>Generalita' del link</h3>
                <MDBRow className="mb-3">
                  <MDBCol>
                    <MDBInput
                      label="Title"
                      value={this.state.linkTitle}
                      onChange={(e) =>
                        this.setState({ linkTitle: e.target.value })
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol>
                    <MDBInput
                      label="Link URL"
                      value={this.state.linkURL}
                      onChange={(e) =>
                        this.setState({ linkURL: e.target.value })
                      }
                    />
                  </MDBCol>
                </MDBRow>
                <MDBRow className="mb-3">
                  <MDBCol>
                    <MDBInput
                      type="textarea"
                      rows="5"
                      label="Description"
                      value={this.state.linkDescription}
                      onChange={(e) =>
                        this.setState({ linkDescription: e.target.value })
                      }
                    />
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol style={{ padding: "15px" }}>
                <h3>Stanze a cui appartiene il link</h3>
                <MDBRow
                  className="mb-3"
                  style={{
                    overflowY: "scroll",
                    height: "100%",
                  }}
                >
                  <MDBCol>
                    {this.state.linkRooms.map((room) => (
                      <MDBRow key={room.id} className="mb-3">
                        <label>
                          <Checkbox
                            checked={
                              this.state.linkSelectedRooms != null &&
                              this.state.linkSelectedRooms.includes(room.id)
                            }
                            onChange={(e) => this.handleCheckedRooms(e, room)}
                          />
                          {" - " + room.name}
                        </label>
                      </MDBRow>
                    ))}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
              <MDBCol style={{ padding: "15px" }}>
                <h3>Categorie a cui appartiene il link</h3>
                <MDBRow
                  className="mb-3"
                  style={{
                    overflowY: "scroll",
                    height: "100%",
                  }}
                >
                  <MDBCol>
                    {this.state.linkCategories.map((room) => (
                      <MDBRow key={room.id} className="mb-3">
                        <label>
                          <Checkbox
                            checked={
                              this.state.linkSelectedCategories != null &&
                              this.state.linkSelectedCategories.includes(
                                room.id
                              )
                            }
                            onChange={(e) =>
                              this.handleCheckedCategories(e, room)
                            }
                          />
                          {" - " + room.name}
                        </label>
                      </MDBRow>
                    ))}
                  </MDBCol>
                </MDBRow>
              </MDBCol>
            </MDBRow>
          </form>
        </MDBContainer>
      </React.Fragment>
    );
  }

  handleCheckedRooms = (e, room) => {
    let checked = e.target.checked;
    if (checked) {
      if (!this.state.linkSelectedRooms.includes(room.id)) {
        let rooms = this.state.linkSelectedRooms;
        rooms.push(room.id);
        this.setState({ linkSelectedRooms: rooms });
      }
    } else {
      let rooms = this.state.linkSelectedRooms.filter(
        (roomID) => roomID != room.id
      );
      this.setState({ linkSelectedRooms: rooms });
    }
  };

  handleCheckedCategories = (e, category) => {
    let checked = e.target.checked;
    if (checked) {
      if (!this.state.linkSelectedCategories.includes(category.id)) {
        let categorys = this.state.linkSelectedCategories;
        categorys.push(category.id);
        this.setState({ linkSelectedCategories: categorys });
      }
    } else {
      let categorys = this.state.linkSelectedCategories.filter(
        (categoryID) => categoryID != category.id
      );
      this.setState({ linkSelectedCategories: categorys });
    }
  };

  saveLink = async () => {
    if (
      this.state.linkTitle.length > 0 &&
      this.state.linkDescription.length > 0 &&
      this.state.linkURL.length > 0 &&
      this.state.linkSelectedCategories.length > 0 //&&
      // this.state.linkSelectedRooms.length > 0
    ) {
      this.setState({ loading: true });
      let needeDeets = {
        title: this.state.linkTitle,
        description: this.state.linkDescription,
        url: this.state.linkURL,
        category: this.state.linkSelectedCategories,
        room: this.state.linkSelectedRooms,
      };

      if (this.state.linkID == null) await firebaseSvc.createLink(needeDeets);
      else await firebaseSvc.updateLinkWithID(this.state.linkID, needeDeets);
      this.setState({ loading: false });
      this.props.history.push(this.state.from);
    }
  };
}

export default withRouter(LinkEditorCmp);
