import React, { Component } from "react";

import firebaseSvc from "../firebaseSvc";
import cookie from "react-cookies";

import Title from "../assets/title_eng.png";
import ReactCountryFlag from "react-country-flag";

import LoadingScreen from "./LoadingScreen";

import { Grid, Fade, IconButton, Zoom, Slide } from "@material-ui/core";

class LandingPage extends Component {
  state = {
    loading: false,
  };

  componentWillMount = async () => {
    this.setState({ loading: true });
    let img = await firebaseSvc.getField("settings", "LandingImage", "picture");
    if (img != null) this.setState({ image: img });
    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <Grid
        container
        justify="center"
        style={{
          height: "100vh",
          maxHeight: "100vh",
          maxWidth: "100vw",
          overflow: "hidden",
          background:
            "linear-gradient(0deg, rgba(47,53,107,1) 46%, rgba(203,100,77,1) 60%, rgba(255,245,200,1) 100%)",
        }}
      >
        <Grid item xs={12} sm={9} md={8} lg={7}>
          <Grid container style={{ height: "100%" }}>
            <Grid container justify="center">
              <Slide direction="down" in={true} mountOnEnter unmountOnExit>
                <img src={Title} className="landingTitle" />
              </Slide>
            </Grid>

            {/* Language buttons */}
            <Grid
              container
              justify="space-around"
              style={{ marginTop: "-5rem" }}
            >
              <Grid>
                <Zoom in={true}>
                  <IconButton
                    onClick={() => {
                      firebaseSvc.language = "ita";
                      cookie.save("language", "ita");
                      this.props.history.push("/");
                    }}
                  >
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode="IT"
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label="Italiano"
                      svg
                    />
                  </IconButton>
                </Zoom>
              </Grid>
              <Grid>
                <Zoom in={true}>
                  <IconButton
                    onClick={() => {
                      firebaseSvc.language = "eng";
                      cookie.save("language", "eng");
                      this.props.history.push("/");
                    }}
                  >
                    <ReactCountryFlag
                      className="emojiFlag"
                      countryCode="GB"
                      style={{
                        fontSize: "2em",
                        lineHeight: "2em",
                      }}
                      aria-label="English"
                      svg
                    />
                  </IconButton>
                </Zoom>
              </Grid>
            </Grid>

            {/* Image */}
            <Grid
              container
              justify="center"
              alignItems="center"
              // style={{ height: "80vh" }}
            >
              <Grid container justify="center" style={{ position: "relative" }}>
                <Fade in={true} timeout={{ enter: 3000 }}>
                  <img src={this.state.image} className="landingImg" />
                </Fade>
              </Grid>
            </Grid>
            {/* {this.state.isAdmin && (
          <MDBRow
            style={{
              justifyContent: "center",
            }}
          >
            <input
              type="file"
              className="custom-file-input"
              id="inputGroupFile01"
              aria-describedby="inputGroupFileAddon01"
              onChange={this.handleImageUpload}
            />
            <label className="btn btn-secondary" htmlFor="inputGroupFile01">
              Carica Immagine
            </label>
          </MDBRow>
        )} */}
          </Grid>
        </Grid>
      </Grid>
    );
  }

  handleImageUpload = async (e) => {
    if (e.target.files[0]) {
      this.setState({ loading: true });
      this.setState({ picture: null });
      const image = e.target.files[0];
      await firebaseSvc.handleImageUpload(
        image,
        "LandingImage",
        "settings",
        "LandingImage",
        this.onImageUploaded
      );
    }
  };

  onImageUploaded = async (url) => {
    console.log("Getting image on db");
    let tPic = await firebaseSvc.getField(
      "Settings",
      "LandingImage",
      "picture"
    );

    //update room
    this.setState({ image: url });

    this.setState({ loading: false });
  };
}

export default LandingPage;
