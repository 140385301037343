import React, { Component } from "react";

import { MDBRow } from "mdbreact";

class Title extends Component {
  state = {
    editable: false,
    onChange: null,
  };

  componentDidMount = () => {
    this.setState({ editable: this.props.editable });
    this.setState({ onChange: this.props.onChange });
  };

  render() {
    if (this.state.editable)
      return (
        <MDBRow>
          <input
            type="text"
            value={this.props.title}
            onChange={this.setName}
            className="display-4 w-100 mt-3"
            style={{ textAlign: "center" }}
          />
        </MDBRow>
      );
    else
      return (
        <MDBRow className="d-flex d-block justify-content-center">
          <h1 className="display-4">{this.props.title}</h1>
        </MDBRow>
      );
  }

  setName = (e) => {
    let nam = e.target.value;
    this.state.onChange && this.state.onChange(nam);
  };
}

export default Title;
