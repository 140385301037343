import React, { Component } from "react";
import { withRouter } from "react-router-dom";

import { MDBContainer, MDBRow, MDBCol } from "mdbreact";

import firebaseSvc from "../firebaseSvc";

class SideNavigation extends Component {
  state = {
    articles: [],
    rooms: [],
  };

  componentDidMount = async () => {
    //list all Rooms
    let rooms = await firebaseSvc.getRooms();
    //console.log(rooms);
    rooms = rooms.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    //console.log(rooms);
    this.setState({ rooms });

    this.setState({ articles: await firebaseSvc.getLatestArticles(9999) });
  };

  render() {
    return (
      <MDBContainer className="p-4">
        {this.state.articles.length > 0 && (
          <React.Fragment>
            <h3>Ultimi Articoli</h3>
            <div className="shadow p-2">
              {this.state.articles.map((article) => (
                <div key={article.id}>
                  <MDBRow>
                    <MDBCol className="col-sm-8">
                      <a href={"/article/" + article.id}>
                        {article.title.substring(0, 40)}
                      </a>
                    </MDBCol>
                    <MDBCol>
                      <p>{firebaseSvc.getArticleDate(article.lastUpdate)}</p>
                    </MDBCol>
                  </MDBRow>
                </div>
              ))}
            </div>
          </React.Fragment>
        )}
      </MDBContainer>
    );
  }
}

export default withRouter(SideNavigation);
