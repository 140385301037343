import React, { Component, View, Text, Image } from "react";
import { withRouter } from "react-router-dom";

import { MDBContainer, MDBJumbotron } from "mdbreact";

import firebaseSvc from "../firebaseSvc";
import cookie from "react-cookies";

class AdminPanel extends React.Component {
  state = {
    isAdmin: false,
    roomsList: [],
    articlesList: [],
  };

  componentWillMount = async () => {
    await this.checkAuth();
  };

  checkAuth = async () => {
    console.log("checking auth");
    if (cookie.load("userID") != null) {
      // console.log("Authenticated");
      // console.log(cookie.load("userID"));
      this.setState({ isAdmin: true });

      //go back to previous route
      if (firebaseSvc.lastPage != null)
        this.props.history.push(firebaseSvc.lastPage);
      else this.props.history.push("/");
      //await this.refreshLists();
    } else {
      // console.log("Unauthenticated!");
      await firebaseSvc.doSignInWithGoogle(this.checkAuth);
    }
  };

  refreshLists = async () => {
    //if we are admin, let's load the stuff!
    this.setState({ roomsList: await firebaseSvc.getList("Rooms") });
    //load the articles
    let articles = await firebaseSvc.getArticles();
    this.setState({ articlesList: articles });
  };

  // updateState = async () => {
  //   let adminCheck = cookie.load("userID") != null;
  //   this.setState({ isAdmin: adminCheck });
  //   await this.refreshLists();
  // };

  onLogout = () => {
    this.props.history.push("/");
  };

  render = () => {
    if (!this.state.isAdmin) {
      return (
        <div
          style={{ height: "100vh" }}
          className="d-flex align-content-center justify-content-center"
        >
          <MDBJumbotron style={{ height: 200 }} className="align-self-center">
            <h1>Spiacente, non sei autorizzato ad entrare qui...</h1>
          </MDBJumbotron>
        </div>
      );
    } else {
      return <MDBContainer></MDBContainer>;
    }
  };
}

export default withRouter(AdminPanel);
