import React, { Component, View, Text } from "react";
import FittedImage from "react-fitted-image";
import convert from "htmr";

import {
  EditorState,
  convertToRaw,
  convertFromRaw,
  ContentState,
} from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

import NavBar from "./elements/NavBar";
import Footer from "./elements/Footer";
import LoadingScreen from "./LoadingScreen";

import {
  MDBContainer,
  MDBMedia,
  MDBMediaBody,
  MDBRow,
  MDBBtn,
  MDBAnimation,
} from "mdbreact";

import firebaseSvc from "../firebaseSvc";

class RoomScreen extends Component {
  state = {
    roomID: null,
    roomDetails: null,
    editorState: EditorState.createEmpty(),
    loading: true,
    latestArticles: [],
  };

  sleep = (ms) => {
    return new Promise((resolve) => setTimeout(resolve, ms));
  };

  componentDidMount = async () => {
    firebaseSvc.activePage = this;
    await this.refresh();
  };

  refresh = async () => {
    this.setState({ loading: true });

    await this.sleep(500);

    let roomID = this.props.match.params.roomID;
    console.log(roomID);
    let rd = await firebaseSvc.getRoomFromID(roomID);

    var contentBlock = null;

    if (rd.description.blocks) {
      const editorState = EditorState.createWithContent(
        convertFromRaw(rd.description)
      );
      this.setState({ editorState });
    } else {
      contentBlock = htmlToDraft(rd.description);

      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks
        );
        const editorState = EditorState.createWithContent(contentState);
        this.setState({ editorState });
      }
    }

    this.setState({ roomID });
    this.setState({ roomDetails: rd });
    this.setState({
      latestArticles: await firebaseSvc.getArticlesInRoom(roomID),
    });

    this.setState({ loading: false });
  };

  render() {
    if (this.state.loading) return <LoadingScreen />;
    return (
      <div style={{ backgroundColor: "#f5f5f5" }}>
        <NavBar />
        <MDBContainer>
          <MDBAnimation type="fadeIn" duration="700ms">
            <MDBRow className="d-flex d-block justify-content-center">
              <h1 className="display-4">{this.state.roomDetails.name}</h1>
            </MDBRow>
          </MDBAnimation>
          <MDBAnimation type="fadeIn" duration="700ms" delay={"150ms"}>
            <MDBRow className="d-flex d-block justify-content-center">
              <MDBRow
                className="mt-md-4 mb-1"
                style={{ alignSelf: "center", height: 400 }}
              >
                {this.state.roomDetails.picture && (
                  <FittedImage
                    src={this.state.roomDetails.picture}
                    fit="cover"
                    className="shadow "
                  />
                )}
              </MDBRow>
            </MDBRow>
          </MDBAnimation>

          <MDBAnimation type="fadeIn" duration="700ms" delay={"300ms"}>
            <MDBRow className="justify-content-md-center">
              <MDBBtn
                outline
                color="info"
                size="lg"
                className="m-4"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/articles",
                    state: {
                      roomID: this.state.roomID,
                    },
                  })
                }
              >
                Articoli
              </MDBBtn>

              <MDBBtn
                outline
                color="info"
                size="lg"
                className="m-4"
                onClick={() =>
                  this.props.history.push({
                    pathname: "/links",
                    state: {
                      roomID: this.state.roomID,
                    },
                  })
                }
              >
                Links
              </MDBBtn>
            </MDBRow>
          </MDBAnimation>

          <MDBAnimation type="fadeIn" duration="700ms" delay={"350ms"}>
            <MDBRow className="p-4">
              <div style={{ width: "100%" }}>
                <Editor
                  editorStyle={{
                    border: "",
                  }}
                  toolbarHidden={true}
                  ReadOnly={true}
                  wrapperClassName="wrapperClassName"
                  editorClassName="editorClassName shadow p-4"
                  editorState={this.state.editorState}
                />
              </div>
            </MDBRow>
          </MDBAnimation>
          <br />

          {this.state.latestArticles.length > 0 && (
            <div>
              <h1 className="mb-5">
                <u>Articoli collegati:</u>
              </h1>
              <MDBContainer
                style={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <ul className="list-unstyled">
                  {this.state.latestArticles.map((article) => (
                    <div key={article.id}>
                      <MDBMedia as="li">
                        <img
                          width={64}
                          height={64}
                          src={article.picture}
                          className="mr-3 mb-3"
                        />
                        <MDBMedia
                          body
                          style={{
                            alignItems: "center",
                          }}
                        >
                          <h3>
                            <a href={"/article/" + article.id}>
                              {article.title}
                            </a>
                          </h3>
                          <p>
                            {firebaseSvc.getArticleDate(article.lastUpdate)}
                          </p>
                        </MDBMedia>
                      </MDBMedia>
                      <hr />
                    </div>
                  ))}
                </ul>
              </MDBContainer>
            </div>
          )}
          <br />
        </MDBContainer>
        <Footer />
      </div>
    );
  }
}

export default RoomScreen;
